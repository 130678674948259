import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { LOGIN, LOGIN_VIA_MICROSOFT, STORE_USER_EMAIL } from 'src/graphql/mutations';
import { useBackendErrorMessage } from 'src/components/hooks';
import { LoginForm, AuthLayout } from 'src/components/Auth';
import { useTranslation } from 'react-i18next';
import { GET_INITIAL_STATE } from 'src/graphql/queries';

const Login: React.FC = (): JSX.Element => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { t } = useTranslation();

  const { data } = useQuery(GET_INITIAL_STATE);

  const setBackendError = useBackendErrorMessage();
  const [login] = useMutation(LOGIN, {
    onError: error => {
      setBackendError(error.message, t('auth.login.loginError'));
    },
    onCompleted: () => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const redirectPath = ((state as Record<string, any>)?.redirect as string) || '/';
      navigate(redirectPath);
    },
    fetchPolicy: 'no-cache',
  });

  const [loginViaMicrosoft, { loading: loadingMicrosoft }] = useMutation(LOGIN_VIA_MICROSOFT, {
    onError: error => {
      setBackendError(error.message, t('auth.login.loginError'));
    },
    onCompleted: () => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const redirectPath = ((state as Record<string, any>)?.redirect as string) || '/';
      navigate(redirectPath);
    },
    fetchPolicy: 'no-cache',
  });

  const [storeEmail] = useMutation(STORE_USER_EMAIL, {
    onError: error => {
      setBackendError(error.message, t('auth.login.storeMailError'));
    },
  });

  const onSubmit = async ({
    rememberUser,
    email,
    password,
  }: {
    rememberUser: boolean;
    email: string;
    password: string;
  }) => {
    rememberUser && (await storeEmail({ variables: { email } }));
    return login({
      variables: { email, password },
    });
  };

  const onSubmitMicrosoft = () => {
    return loginViaMicrosoft();
  };

  return (
    <AuthLayout title={t('auth.login.title')} showSlideshow>
      <LoginForm
        onSubmit={onSubmit}
        onSubmitMicrosoft={onSubmitMicrosoft}
        isSubmittingMicrosoft={loadingMicrosoft}
        prefilledEmail={data?.userEmail || undefined}
      />
    </AuthLayout>
  );
};
export default Login;
