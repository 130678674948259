import React, { useEffect } from 'react';
import welcomePending from 'src/assets/svgs/welcome_pending.svg';
import { Box } from '@mui/material';
import { FadeMount } from 'src/components/Base';
import { useMutation } from '@apollo/client';
import { useUserData, useWelcomeSlides } from 'src/components/hooks';
import { UPDATE_ONBOARDING } from 'src/graphql/mutations';
import { GET_USER_LOGIN_DETAILS } from 'src/graphql/queries';

const Success = ({ isActive, formikRef }: { isActive: boolean; formikRef: HTMLHeadingElement }) => {
  const { currentSlide, slides, openSuccessDialog } = useWelcomeSlides();
  const { company } = useUserData();
  const [onboardingCompleted] = useMutation(UPDATE_ONBOARDING, {
    update: (
      cache,
      {
        data: {
          completeWelcomeSlides: { user: updatedUser },
        },
      }
    ) => {
      cache.writeQuery({
        query: GET_USER_LOGIN_DETAILS,
        data: {
          company: company,
          user: updatedUser,
        },
      });
    },
    onCompleted: () => {
      openSuccessDialog();
    },
    fetchPolicy: 'no-cache',
  });

  //onboardingCompleted();
  useEffect(() => {
    if (currentSlide === slides.length - 1) {
      onboardingCompleted();
    }
  }, [currentSlide, slides, onboardingCompleted]);

  return (
    <Box
      sx={{
        display: 'flex',
        position: 'relative',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <FadeMount>
        <img
          style={{
            width: '280px',
            margin: '100px 32px 32px 32px',
          }}
          src={welcomePending}
          alt="Welcome Pending"
        />
      </FadeMount>
    </Box>
  );
};

export default Success;
