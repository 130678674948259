import React from 'react';
import { Box, Grid, MenuItem, Typography } from '@mui/material';

import { useTheme } from '@mui/material/styles';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import { useTranslation } from 'react-i18next';
import { getUserInfoSchema } from 'src/constants/schemas';
import { departments, positions } from 'src/constants/domainData';
import { useUserData } from 'src/components/hooks';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ProfileInfo = ({ handleSubmit, formikRef }: { handleSubmit: any; formikRef: any }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { user } = useUserData();

  const userInfoSchema = getUserInfoSchema(t);
  const initialValues = userInfoSchema.getDefault();

  // If possible, use values in cache
  if (user) {
    user.email && (initialValues.email = user.email);
  }

  return (
    <Box
      sx={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        minHeight: '400px',
      }}
    >
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validateOnBlur={true}
        validateOnChange={true}
        validationSchema={userInfoSchema}
        onSubmit={async (values, { setSubmitting }) => {
          await handleSubmit(values);
          setSubmitting(false);
        }}
      >
        {() => (
          <Form data-cy="profile-form">
            <Grid container spacing={1}>
              <Grid item xs={12} pb={2}>
                <Typography variant="h6" sx={{ textAlign: 'start' }} data-cy="page-title">
                  {t('welcomeSlides.profileForm.formTitle')}
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{ paddingBottom: theme.spacing(1) }}>
                <Field
                  fullWidth
                  component={TextField}
                  type="text"
                  label={t('welcomeSlides.profileForm.firstNameLabel')}
                  name="firstName"
                  data-cy="firstName"
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sx={{ paddingBottom: theme.spacing(1) }}>
                <Field
                  fullWidth
                  component={TextField}
                  type="text"
                  label={t('welcomeSlides.profileForm.lastNameLabel')}
                  name="lastName"
                  data-cy="lastName"
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sx={{ paddingBottom: theme.spacing(1) }}>
                <Field
                  fullWidth
                  component={TextField}
                  type="text"
                  label={t('welcomeSlides.profileForm.phoneLabel')}
                  name="mobileNumber"
                  data-cy="mobileNumber"
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sx={{ paddingBottom: theme.spacing(1) }}>
                <Field
                  fullWidth
                  component={TextField}
                  type="text"
                  label={t('welcomeSlides.profileForm.emailLabel')}
                  name="email"
                  data-cy="email"
                  variant="outlined"
                  size="small"
                />
              </Grid>

              <Grid item xs={12} sx={{ paddingBottom: theme.spacing(1) }}>
                <Field
                  fullWidth
                  type="text"
                  align="left"
                  component={TextField}
                  select={true}
                  label={t('welcomeSlides.profileForm.departmentLabel')}
                  name="departmentId"
                  data-cy="department"
                  variant="outlined"
                  size="small"
                >
                  {departments.map(({ label, value }, index) => {
                    return (
                      <MenuItem key={index} value={value}>
                        {label}
                      </MenuItem>
                    );
                  })}
                </Field>
              </Grid>
              <Grid item xs={12} sx={{ paddingBottom: theme.spacing(1) }}>
                <Field
                  fullWidth
                  component={TextField}
                  style={{
                    width: '100%',
                  }}
                  type="text"
                  align="left"
                  select={true}
                  label={t('welcomeSlides.profileForm.positionLabel')}
                  name="position"
                  data-cy="position"
                  variant="outlined"
                  size="small"
                >
                  {positions.map(({ label, value }, index) => {
                    return (
                      <MenuItem key={index} value={value}>
                        {label}
                      </MenuItem>
                    );
                  })}
                </Field>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default ProfileInfo;
