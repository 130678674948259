import React, { memo } from 'react';
import { Route, Routes } from 'react-router-dom';
import {
  Controlling,
  DataManagement,
  Esg,
  Home,
  InviteUsers,
  Materiality,
  NotFound,
  Reporting,
  Settings,
  Welcome,
} from 'src/containers';
import { Layout } from 'src/components/Structure';
import { useUserData } from 'src/components/hooks';

interface Props {
  authenticatedRoutes?: string[];
}

const userRoutes = [
  {
    path: '/',
    Element: Home,
  },
  {
    path: '/controlling',
    Element: Controlling,
  },
  {
    path: '/data-management',
    Element: DataManagement,
  },
  {
    path: '/esg',
    Element: Esg,
  },
  {
    path: '/materiality',
    Element: Materiality,
  },
  {
    path: '/reporting',
    Element: Reporting,
  },
  {
    path: '/settings',
    Element: Settings,
  },
  {
    path: '/welcome',
    Element: Welcome,
  },
];

const adminRoutes = [
  {
    path: '/welcome/invite-users',
    Element: InviteUsers,
  },
];

const AuthenticatedRoutes: React.FC<Props> = () => {
  const { user } = useUserData();

  if (user?.isAdmin) {
    userRoutes.push(...adminRoutes);
  }

  return (
    <Routes>
      {userRoutes.map(({ path, Element }) => {
        return (
          <Route
            key={path}
            path={path}
            element={
              <Layout>
                <Element />
              </Layout>
            }
          />
        );
      })}
      <Route path="/*" element={<NotFound />} />
    </Routes>
  );
};
export default memo(AuthenticatedRoutes);
