import { alpha } from '@mui/material';
import { Theme } from '@mui/material/styles';

export const getListItemStyles = (theme: Theme, drawerExpanded: boolean) => {
  return {
    justifyContent: drawerExpanded ? 'flex-start' : 'center',
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: '10px',
    height: '48px',
    padding: 0,
    backgroundColor: 'transparent',
    transition: theme.transitions.create('all', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shorter,
    }),
    width: drawerExpanded ? '240px' : '64px',
    pl: '1px',
    pr: drawerExpanded ? '20px' : '0px',
    color: theme.palette.text.secondary,
    fontWeight: '400 !important',
    fontSize: '1.125rem !important',
    '&:hover': {
      backgroundColor: theme.palette.charts.social[1] as string,
      fontWeight: '500 !important',
    },
    '&.Mui-selected': {
      fontWeight: '500 !important',
      backgroundColor: alpha(theme.palette.primary.main, 0.2),
      color: theme.palette.primary.main,
      '& .MuiSvgIcon-root': { color: theme.palette.primary.main },
    },
  };
};

export const getToggleButtonStyles = (theme: Theme, drawerExpanded: boolean) => {
  return {
    position: 'fixed',
    border: '2.5px solid',
    borderColor: 'text.secondary',
    width: theme.spacing(4),
    height: theme.spacing(4),
    borderRadius: theme.spacing(4),
    top: '80px',
    left: drawerExpanded
      ? (theme.custom.drawerWidth as string)
      : (theme.custom.drawerWidthClosed as string),
    transition: theme.transitions.create('all', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: theme.palette.charts.social[1] as string,
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    transform: `translateX(-50%) rotate(${drawerExpanded ? '0' : '180'}deg)`,
  };
};
