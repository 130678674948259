import React, { ReactNode, useEffect, useState } from 'react';
import { useMessages, useUserData } from 'src/components/hooks';
import { GenericDialog, Loader } from 'src/components/Base';
import { AuthLayout } from 'src/components/Auth/index';
import { LoadingButton } from 'src/components/Forms';
import {
  getIubendaTermsAndConditions,
  getLatestConsentForUser,
  getLatestLegalNotice,
  postConsentForUser,
} from 'src/utils/iubendaService';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

const apiKey = (process.env.REACT_APP_IUBENDA_API_KEY as string) || undefined;

// Disable if cypress is running (ci mode)
const isCiMode = (process.env.REACT_APP_CI_MODE as string) === 'true';

const TermsAndConditionsGuard = ({ children }: { children: ReactNode }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [content, setContent] = useState({
    termsAndConditions: undefined,
    privacyPolicy: undefined,
    cookiePolicy: undefined,
  });
  const [latestTermsAndConditionsAccepted, setLatestTermsAndConditionsAccepted] = useState(false);
  const { user } = useUserData();
  const { t } = useTranslation();
  const { setSuccessMessage } = useMessages();

  useEffect(() => {
    const checkConsent = async () => {
      if (user && apiKey && !isCiMode) {
        const latestTermsAndConditions = await getLatestLegalNotice();
        const latestConsentForUser = await getLatestConsentForUser(user.email);
        setLatestTermsAndConditionsAccepted(
          latestConsentForUser &&
            latestConsentForUser.timestamp > latestTermsAndConditions.timestamp
        );
        if (!latestTermsAndConditionsAccepted) {
          const termsAndConditions = await getIubendaTermsAndConditions();
          setContent({ ...content, termsAndConditions: termsAndConditions });
        }
        setIsLoading(false);
      }
    };
    checkConsent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAcceptTermsAndConditions = async () => {
    if (user) {
      postConsentForUser(user.email).then(() => {
        setSuccessMessage(t('termsAndConditions.successMessage'));
        setLatestTermsAndConditionsAccepted(true);
      });
    }
  };

  if (!apiKey || isCiMode) {
    return <>{children}</>;
  }

  if (isLoading) {
    return <Loader />;
  }

  if (!latestTermsAndConditionsAccepted) {
    return (
      <AuthLayout title={t('termsAndConditions.title')}>
        <GenericDialog
          title={t('termsAndConditions.title')}
          width="100%"
          isOpen={true}
          dialogActions={
            <LoadingButton onClick={handleAcceptTermsAndConditions}>
              {t('termsAndConditions.buttonText')}
            </LoadingButton>
          }
        >
          {content.termsAndConditions && (
            <Box
              sx={{
                '& h1': {
                  fontSize: '1.875rem',
                },
                '& h2': {
                  fontSize: '1.75rem',
                },
                '& h3': {
                  fontSize: '1.5rem',
                },
                '& h4': {
                  fontSize: '1.25rem',
                },
                '& p': {
                  fontSize: '1rem',
                },
                '& li': {
                  fontSize: '1rem',
                },
              }}
              dangerouslySetInnerHTML={{ __html: content.termsAndConditions }}
            />
          )}
        </GenericDialog>
      </AuthLayout>
    );
  }

  return <>{children}</>;
};

export default TermsAndConditionsGuard;
