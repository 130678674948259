import React from 'react';
import { styled, useTheme, CSSObject, Theme } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';

interface DrawerProps {
  open?: boolean;
  theme?: Theme;
  children: React.ReactNode;
}

const openedMixin = (theme: Theme): CSSObject => ({
  width: theme.custom.drawerWidth as string,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  borderRight: 'none',
});

const closedMixin = (theme: Theme): CSSObject => ({
  width: theme.custom.drawerWidthClosed as string,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  borderRight: 'none',
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: prop => prop !== 'open' })(
  ({ theme, open }) => ({
    width: theme.custom.drawerWidth as string,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  })
);

const DrawerMenu = ({ open, children }: DrawerProps) => {
  const theme = useTheme();
  return (
    <Drawer
      variant="permanent"
      open={open}
      theme={theme}
      data-cy={open ? 'menu-is-open' : 'menu-is-closed'}
    >
      {children}
    </Drawer>
  );
};

export default DrawerMenu;
