import { Divider, Box, Typography, Container, Link, TextField } from '@mui/material';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Julius from 'src/assets/img/julius.png';
import { LoadingButton } from 'src/components/Forms/index';
import { useTheme } from '@mui/material/styles';
import { useLogEvent } from 'src/components/hooks';

const SupportForm = ({
  onSubmit,
  isSubmitting,
}: {
  onSubmit: (message: string) => void;
  isSubmitting: boolean;
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { captureEvent } = useLogEvent();
  const textFieldRef = useRef<HTMLTextAreaElement | null>(null);

  const handleClick = () => {
    onSubmit(textFieldRef.current?.value as string);
    captureEvent('support_message_sent');
  };
  return (
    <>
      <Container
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          color: 'text.secondary',
        }}
      >
        <Box py={0.75}>
          <img src={Julius} alt="julius" style={{ width: '120px', height: '120px' }} />
        </Box>
        <Box py={0.75}>
          <Typography variant="body2">{t('supportDialog.name')}</Typography>
        </Box>
        <Box py={0.75}>
          <Typography variant="body2">{t('supportDialog.contact')}</Typography>
        </Box>
        <Box py={0.75}>
          <Typography variant="body2">{t('supportDialog.email')}</Typography>
        </Box>
        <Box pt={0.75} pb={3}>
          <Link href="https://calendly.com/julius-engbert/30min" target="_blank" rel="noreferrer">
            <Typography variant="subtitle1">{t('supportDialog.linkText')}</Typography>
          </Link>
        </Box>
      </Container>
      <Box>
        <Divider>
          <Typography sx={{ position: 'relative', top: '-8px', color: 'text.secondary' }}>
            <Typography variant="body2">{t('supportDialog.dividerText')}</Typography>
          </Typography>
        </Divider>
      </Box>
      <Box
        component="form"
        sx={{
          position: 'relative',
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          color: 'text.secondary',
          height: '116px',
          '& .MuiInputBase-root': {
            height: '200px',
            width: '100%',
            backgroundColor: theme.palette.background.default,
          },
        }}
      >
        <TextField
          inputRef={textFieldRef}
          fullWidth
          multiline
          rows={4}
          disabled={isSubmitting}
          variant="outlined"
          placeholder={t('supportDialog.inputPlaceholder')}
        />
      </Box>
      <Box
        pt={11}
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <LoadingButton onClick={handleClick} loading={isSubmitting} sx={{ width: '80%' }}>
          {t('supportDialog.buttonText')}
        </LoadingButton>
      </Box>
    </>
  );
};

export default SupportForm;
