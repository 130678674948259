import firstImage from 'src/assets/svgs/auth/auth_carousel_1.svg';
import secondImage from 'src/assets/svgs/auth/auth_carousel_2.svg';
import thirdImage from 'src/assets/svgs/auth/auth_carousel_3.svg';

const carouselContent = [
  {
    title: 'auth.signupCarouselContent.first.title',
    subtitle: 'auth.signupCarouselContent.first.subtitle',
    imageSrc: firstImage,
  },
  {
    title: 'auth.signupCarouselContent.second.title',
    subtitle: 'auth.signupCarouselContent.second.subtitle',
    imageSrc: secondImage,
  },
  {
    title: 'auth.signupCarouselContent.third.title',
    subtitle: 'auth.signupCarouselContent.third.subtitle',
    imageSrc: thirdImage,
  },
];

export default carouselContent;
