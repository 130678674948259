import { ApolloClient, ApolloLink, createHttpLink, from, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/link-error';
import { setContext } from '@apollo/link-context';
import resolvers from 'src/graphql/resolvers';
import { CachePersistor, LocalStorageWrapper } from 'apollo3-cache-persist';
import { GET_INITIAL_STATE, GET_RESET_STATE } from './queries';
import { Firebase } from 'src/components/Contexts';
import { DefaultOptions } from '@apollo/client';
import typePolicies from './typePolicies';
import typeDefs from 'src/graphql/typeDefs';

interface DefaultOptionsWithFirebase extends DefaultOptions {
  firebase: Firebase;
}

type ApolloClientProps = {
  uri: string;
  firebase: Firebase;
};

const initialState = {
  drawerOpen: true,
  userEmail: undefined,
};

const resetState = {
  drawerOpen: true,
  userEmail: undefined,
};

// Persistant storage definition
const cache = new InMemoryCache({
  typePolicies,
});
export const cachePersistor = new CachePersistor({
  cache,
  debounce: 1000,
  storage: new LocalStorageWrapper(window.localStorage),
});

const initializeApolloClient = async ({
  uri,
  firebase,
}: ApolloClientProps): Promise<ApolloClient<unknown>> => {
  // write initial state so local queries dont error out
  cache.writeQuery({
    query: GET_INITIAL_STATE,
    data: initialState,
  });

  // restore state from localStorage
  await cachePersistor.restore();

  // reset state so e.g. create modal does not open
  cache.writeQuery({
    query: GET_RESET_STATE,
    data: resetState,
  });

  // Graphql endpoint
  const httpLink = createHttpLink({ uri });

  // Authorization header
  const authLink = setContext(async (_, { headers }) => {
    let customHeaders = {};

    try {
      const currentUser = firebase.getAuth().currentUser;
      const token = currentUser ? await currentUser.getIdToken() : null;

      customHeaders = {
        authorization: token ? `Bearer ${token}` : '',
      };
    } catch (e) {}

    return {
      headers: {
        ...headers,
        ...customHeaders,
      },
    };
  });

  // Middleware
  const middlewareLink = new ApolloLink((operation, forward) => {
    // ToDo Clemens: Insert middleware
    return forward(operation);
  });

  // Error handling
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path }) => {
        const error = `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`;
        return console.error(error);
      });
    }

    if (networkError) console.error(`[Network error]: ${networkError}`);
  });

  const link = from([errorLink, middlewareLink, authLink.concat(httpLink)]);

  const options: DefaultOptionsWithFirebase = {
    firebase,
    watchQuery: {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
    },
  };

  const client = new ApolloClient({
    link,
    resolvers,
    cache,
    typeDefs,
    connectToDevTools: process.env.NODE_ENV === 'development',
    defaultOptions: options,
  });

  return client;
};

export default initializeApolloClient;
