import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { MobileWarning } from 'src/components/Structure';
import { useTranslation } from 'react-i18next';
import {
  Login,
  NotFound,
  Register,
  RegisterInvited,
  TokenizedLogin,
  PasswordForgot,
} from 'src/containers';
import AuthenticatedRoutes from 'src/routes/AuthenticatedRoutes';
import { PrivateRoute, TermsAndConditionsGuard } from 'src/components/Auth';
import {
  BugsnagProvider,
  HeadProvider,
  SupportProvider,
  PosthogProvider,
  WelcomeSlidesProvider,
} from 'src/components/Contexts';

const App = () => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <Helmet>
        <meta name="description" content={t('meta.description')} />
      </Helmet>
      <>
        <HeadProvider>
          <MobileWarning />
          <BrowserRouter>
            <QueryParamProvider adapter={ReactRouter6Adapter}>
              <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/invitation" element={<RegisterInvited />} />
                <Route path="/password-forgot" element={<PasswordForgot />} />
                <Route path="/verify-user" element={<TokenizedLogin />} />
                <Route
                  path="/*"
                  element={
                    <PrivateRoute>
                      <TermsAndConditionsGuard>
                        <BugsnagProvider>
                          <PosthogProvider>
                            <WelcomeSlidesProvider>
                              <SupportProvider>
                                <AuthenticatedRoutes />
                              </SupportProvider>
                            </WelcomeSlidesProvider>
                          </PosthogProvider>
                        </BugsnagProvider>
                      </TermsAndConditionsGuard>
                    </PrivateRoute>
                  }
                />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </QueryParamProvider>
          </BrowserRouter>
        </HeadProvider>
      </>
    </Fragment>
  );
};

export default App;
