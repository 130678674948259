import React from 'react';
import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import { PageSection, PageContainer, PageTitle } from 'src/components/Structure';
import { useTranslation } from 'react-i18next';
import { usePageTitle, useUserData } from 'src/components/hooks';
import { LinkItem } from 'src/components/Base';
import { signupCards, prioritiesCards } from 'src/constants/welcomeScreenCards';
import { useNavigate } from 'react-router-dom';
import Facilities from 'src/assets/svgs/welcome/facilities.svg';
import Esg from 'src/assets/svgs/welcome/esg.svg';
import TeamMembers from 'src/assets/svgs/welcome/teamMembers.svg';
import TaskManagement from 'src/assets/svgs/welcome/taskManagement.svg';
import ChatBot from 'src/assets/svgs/welcome/chatBot.svg';
import useSupport from 'src/components/hooks/useSupport';

const cardStyles = { cursor: 'pointer', position: 'relative', height: '100%' };

const cardContentStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  flexDirection: 'column',
  minHeight: '244px',
  width: '100%',
  height: '100%',
  position: 'relative',
};

const imageBoxStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100px',
  py: 1.5,
};

const position: 'absolute' | 'relative' | 'fixed' = 'relative';
const imageStyles = {
  position: position,
  height: '100%',
  width: '90%',
};

const getCardContent = (id: string) => {
  if (id === 'activities') {
    return (
      <Box
        sx={{
          ...imageStyles,
          width: '100%',
          backgroundColor: 'primary.main',
          color: 'white',
          borderRadius: '10px',
          padding: 2,
        }}
      >
        <Typography variant={'subtitle1'}>Activity feed not implemented yet.</Typography>
      </Box>
    );
  }

  if (id === 'facilities') {
    return <img src={Facilities} alt="facilities" style={imageStyles} />;
  }

  if (id === 'esg') {
    return <img src={Esg} alt="esg" style={imageStyles} />;
  }

  if (id === 'teamMembers') {
    return <img src={TeamMembers} alt="teamMembers" style={imageStyles} />;
  }

  if (id === 'taskManagement') {
    return <img src={TaskManagement} alt="taskManagement" style={imageStyles} />;
  }

  if (id === 'support') {
    return <img src={ChatBot} alt="support" style={imageStyles} />;
  }
};

const Welcome: React.FC = (): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setHelmetTitle } = usePageTitle();
  setHelmetTitle(t('navigation.drawer.home'));
  const { user } = useUserData();
  const { handleOpenSupport } = useSupport();

  const handleCardAction = (id: string, to: string | undefined) => {
    if (id === 'support') {
      handleOpenSupport();
    } else {
      navigate(to as string);
    }
  };

  return (
    <PageContainer>
      <PageTitle
        title={t('routes.welcome.title', { firstName: user?.firstName })}
        subtitle={t('routes.welcome.subtitle')}
        showLightTitle
        showDivider
      />
      <PageSection
        TitleRowActionItem={LinkItem}
        titleRowActionItemProps={{
          to: '/',
          textIdentifier: 'routes.welcome.sections.start.linkText',
          showUnderline: 1,
        }}
        title={t('routes.welcome.sections.start.title')}
        spacing={2}
        showDivider
      >
        <>
          {signupCards.map(
            (
              {
                id,
                title,
                to,
                text,
                subtitle,
              }: { id: string; title: string; to: string; subtitle: string; text: string },
              index
            ) => {
              return (
                <Grid item xs={4} key={index}>
                  <Card onClick={() => handleCardAction(id, to)} sx={cardStyles}>
                    <CardContent sx={cardContentStyles}>
                      <Typography variant="body1">{t(title)}</Typography>
                      <Box sx={imageBoxStyles}>{getCardContent(id)}</Box>
                      <Box>
                        <LinkItem showArrow to={to} textIdentifier={subtitle} />
                        <Typography variant="subtitle1" color="text.grey">
                          {t(text)}
                        </Typography>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              );
            }
          )}
        </>
      </PageSection>
      <PageSection title={t('routes.welcome.sections.priorities.title')} spacing={2}>
        <>
          {prioritiesCards.map(
            (
              {
                id,
                title,
                to,
                text,
                subtitle,
              }: { id: string; title: string; to?: string; subtitle: string; text: string },
              index
            ) => {
              return (
                <Grid item xs={4} key={index}>
                  <Card onClick={() => handleCardAction(id, to)} sx={cardStyles}>
                    <CardContent sx={cardContentStyles}>
                      <Typography variant="body1">{t(title)}</Typography>
                      <Box sx={imageBoxStyles}>{getCardContent(id)}</Box>
                      <Box>
                        <Typography variant="body1" sx={{ fontWeight: 500 }}>
                          {t(subtitle)}
                        </Typography>
                        <Typography variant="subtitle1" color="text.grey">
                          {t(text)}
                        </Typography>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              );
            }
          )}
        </>
      </PageSection>
    </PageContainer>
  );
};

export default Welcome;
