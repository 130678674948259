import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthLayout, RegistrationInvitedForm, ResendVerification } from 'src/components/Auth';
import { REGISTER_INVITED, RESEND_INVITE_MAIL } from 'src/graphql/mutations';
import { useMutation } from '@apollo/client';
import { ErrorState } from 'src/components/Structure';
import { useQueryParam, StringParam } from 'use-query-params';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/system';
import { useBackendErrorMessage, useMessages } from 'src/components/hooks';

const RegisterInvited = () => {
  const { t } = useTranslation();
  const [hasError, setHasError] = useState(false);
  const navigate = useNavigate();
  const { setSuccessMessage } = useMessages();
  const setBackendError = useBackendErrorMessage();
  const [token] = useQueryParam('token', StringParam);
  const [email] = useQueryParam('email', StringParam);

  const [register] = useMutation(REGISTER_INVITED, {
    onError: error => {
      setBackendError(error.message, t('auth.registration.registrationError'));
      setHasError(true);
    },
    onCompleted: () => {
      setSuccessMessage(t('auth.registration.registrationSuccess'));
      navigate('/');
    },
    fetchPolicy: 'no-cache',
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit = (values: Record<string, any>) => {
    const { password, email } = values;
    return register({
      variables: {
        email,
        password,
        token,
      },
    });
  };

  return (
    <AuthLayout title="Register" showSlideshow>
      {!token && (
        <Box
          sx={{
            transform: 'translateY(50%)',
          }}
        >
          <ErrorState
            error={t('auth.registration.verificationForm.noToken.errorTitle')}
            errorSubtitle={t('auth.registration.verificationForm.noToken.errorSubtitle')}
            to="/login"
            label={t('auth.registration.verificationForm.noToken.linkText')}
            center={false}
          />
        </Box>
      )}
      {hasError && (
        <ResendVerification
          title={t('auth.registration.invitationForm.expired.errorTitle')}
          subtitle={t('auth.registration.invitationForm.expired.errorSubtitle')}
          buttonText={t('auth.registration.invitationForm.expired.buttonText')}
          email={email as string}
          mutation={RESEND_INVITE_MAIL}
        />
      )}
      {token && !hasError && <RegistrationInvitedForm onSubmit={onSubmit} />}
    </AuthLayout>
  );
};

export default RegisterInvited;
