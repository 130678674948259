import axios from 'axios';
import filter from 'lodash/filter';
import sortBy from 'lodash/sortBy';
import reverse from 'lodash/reverse';

const apiKey = process.env.REACT_APP_IUBENDA_API_KEY as string;
const isCiMode = (process.env.REACT_APP_CI_MODE as string) === 'true';

const termsAndConditionsUrl = `https://www.iubenda.com/api/terms-and-conditions/83199036`;
const privacyPolicyUrl = `https://www.iubenda.com/api/privacy-policy/83199036`;
const cookiePolicyUrl = `https://www.iubenda.com/api/privacy-policy/83199036/cookie-policy`;

const consentUrl = 'https://consent.iubenda.com/consent';
const legalNoticesUrl = 'https://consent.iubenda.com/legal_notices';
const headers = { ApiKey: apiKey, 'Content-Type': 'application/json' };

export const getIubendaTermsAndConditions = async () => {
  try {
    const { data } = await axios.get(termsAndConditionsUrl);
    return data.content;
  } catch (error) {
    throw error;
  }
};

// Currently not displayed
export const getIubendaPrivacyPolicy = async () => {
  try {
    const {
      data: { content },
    } = await axios.get(privacyPolicyUrl);
    return content;
  } catch (error) {
    throw error;
  }
};

// Currently not displayed
export const getIubendaCookiePolicy = async () => {
  try {
    const {
      data: { content },
    } = await axios.get(cookiePolicyUrl);
    return content;
  } catch (error) {
    throw error;
  }
};

export const getLatestLegalNotice = async () => {
  if (apiKey && !isCiMode) {
    try {
      const { data } = await axios.get(legalNoticesUrl, { headers: headers });
      return sortBy(data, 'timestamp')[data.length - 1];
    } catch (error) {
      throw error;
    }
  }
};

export const getLatestConsentForUser = async (email: string) => {
  if (apiKey && !isCiMode) {
    try {
      const { data } = await axios.get(consentUrl, { headers: headers });
      return reverse(sortBy(filter(data, { subject: { email: email } }), 'timestamp'))[0];
    } catch (error) {
      throw error;
    }
  }
};

export const postConsentForUser = async (email: string) => {
  if (apiKey && !isCiMode) {
    try {
      const {
        data: { content },
      } = await axios.post(
        consentUrl,
        JSON.stringify({
          subject: {
            email: email,
          },
        }),
        { headers: headers }
      );
      return content;
    } catch (error) {
      throw error;
    }
  }
};
