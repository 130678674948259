import * as Yup from 'yup';
import { TFunction } from 'react-i18next';
import { departments, positions } from 'src/constants/domainData';

export const getUserInfoSchema = (t: TFunction) => {
  return Yup.object().shape({
    firstName: Yup.string().default('').required(t('fieldWarnings.requiredWarning')),
    lastName: Yup.string().default('').required(t('fieldWarnings.requiredWarning')),
    mobileNumber: Yup.number().typeError(t('fieldWarnings.numberWarning')),
    email: Yup.string()
      .default('')
      .email(t('fieldWarnings.emailWarning'))
      .required(t('fieldWarnings.emailWarning')),
    departmentId: Yup.number()
      .required(t('fieldWarnings.requiredWarning'))
      .oneOf(departments.map(department => department.value)),
    position: Yup.string()
      .default('')
      .required(t('fieldWarnings.requiredWarning'))
      .oneOf(positions.map(position => position.value)),
  });
};

export const getCompanyInfoSchema = (t: TFunction) => {
  return Yup.object().shape({
    name: Yup.string().default('').required(t('fieldWarnings.requiredWarning')),
    headquarters: Yup.string().default('').required(t('fieldWarnings.requiredWarning')),
    industryId: Yup.number().required(t('fieldWarnings.requiredWarning')),
    subIndustryId: Yup.number().required(t('fieldWarnings.requiredWarning')),
    employeesNum: Yup.string().default('').required(t('fieldWarnings.requiredWarning')),
  });
};

export const getInviteUserSchema = (t: TFunction) => {
  return Yup.object().shape({
    teamMembers: Yup.array()
      .of(
        Yup.object().shape({
          email: Yup.string()
            .default('')
            .email(t('fieldWarnings.emailWarning'))
            .required(t('fieldWarnings.requiredWarning')),
          position: Yup.string().default(''),
          role: Yup.string().default('').required(t('fieldWarnings.requiredWarning')),
        })
      )
      .min(1, t('fieldWarnings.requiredWarning')),
  });
};
