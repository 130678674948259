import LogoImg from 'src/assets/svgs/mavue_logo.svg';
import LogoSmallImg from 'src/assets/svgs/mavue_icon.svg';
import React from 'react';
import { Box, Fade } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const DrawerHead = ({ drawerExpanded }: { drawerExpanded: boolean }) => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        cursor: 'pointer',
        height: '96px',
      }}
      onClick={() => navigate('/')}
    >
      <Fade in={drawerExpanded}>
        <img
          src={LogoImg}
          alt="logo"
          style={{
            marginTop: '40px',
            width: '124px',
            marginLeft: '32px',
          }}
        />
      </Fade>
      <Fade in={!drawerExpanded}>
        <img
          src={LogoSmallImg}
          alt="logo"
          style={{
            marginTop: '-31px',
            marginLeft: '30.25px',
            width: '36px',
          }}
        />
      </Fade>
    </Box>
  );
};

export default DrawerHead;
