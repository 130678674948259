import React, { useEffect, createContext } from 'react';
import Bugsnag from '@bugsnag/js';
import { useUserData } from 'src/components/hooks';

const apiKey = (process.env.REACT_APP_BUGSNAG_API_KEY as string) || '';
const BugsnagContext = createContext(null);

const BugsnagProvider = ({ children }: { children: React.ReactElement }) => {
  const { user, company } = useUserData();

  useEffect(() => {
    if (!apiKey) return;

    if (user) {
      Bugsnag.start({ apiKey: apiKey as string });
      const { id, firstName, lastName, email } = user;
      Bugsnag.setUser(id, email, firstName + ' ' + lastName);
    }
    if (company && company?.id && company?.name) {
      Bugsnag.addMetadata('user', {
        companyID: company.id,
        company: company.name,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!apiKey) return <>{children}</>;

  return <BugsnagContext.Provider value={null}>{children}</BugsnagContext.Provider>;
};

export { BugsnagProvider };

export default BugsnagContext;
