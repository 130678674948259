import logo from 'src/assets/svgs/mavue_logo.svg';
import dashboard from 'src/assets/svgs/auth/dashboard_screen.svg';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Box } from '@mui/material';
import { LayoutProps } from 'src/components/Auth/AuthInterfaces';
import { useTheme } from '@mui/system';
import SignupCarousel from 'src/components/Auth/SignupCarousel';
import { FadeMount } from 'src/components/Base';

const AuthLayout = ({
  title,
  children,
  showGreyBackground,
  showDashboard,
  showSlideshow,
  smallTopPadding,
}: LayoutProps) => {
  const theme = useTheme();
  return (
    <Box
      component="div"
      sx={{
        display: 'flex',
        height: '100vh',
        minHeight: '100%',
        backgroundColor: showGreyBackground ? 'background.default' : 'transparent',
      }}
    >
      <Helmet>
        <title>{title} - Mavue</title>
      </Helmet>
      <Box
        component="img"
        src={logo}
        alt="Mavue logo"
        sx={{
          position: 'absolute',
          top: '6%',
          left: '6%',
          width: 146,
        }}
      />
      <Box
        component="div"
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'row',
          flexGrow: '1',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          padding: smallTopPadding ? '8% 6% 4% 6%' : '10% 6% 4% 6%',
          width: '50%',
        }}
      >
        <Box
          component="div"
          sx={{
            width: '100%',
            height: '100%',
          }}
        >
          {children}
        </Box>
      </Box>
      <Box
        component="div"
        sx={{
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '50%',
          height: '100%',
          backgroundColor: showSlideshow && theme.palette.background.paper,
        }}
      >
        {showSlideshow && <SignupCarousel />}

        {showDashboard && (
          <FadeMount>
            <Box
              component="img"
              src={dashboard}
              alt="Mavue Analytics Dashboard"
              sx={{
                position: 'absolute',
                width: '95%',
                top: '45%',
                transform: 'translateY(-50%)',
                right: '0px',
              }}
            />
          </FadeMount>
        )}
      </Box>
    </Box>
  );
};

export default AuthLayout;
