import React from 'react';
import { Box } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { useTheme } from '@mui/system';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import DrawerHead from 'src/components/Drawer/DrawerHead';
import DrawerMenu from 'src/components/Drawer/DrawerMenu';
import DrawerBottom from 'src/components/Drawer/DrawerBottom';
import { getToggleButtonStyles } from 'src/components/Drawer/styles';

interface DrawerProps {
  drawerExpanded: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  toggleDrawer: any;
}

const ToggleButton = ({ toggleDrawer, drawerExpanded }: DrawerProps) => {
  const theme = useTheme() as Theme;
  return (
    <Box
      onClick={toggleDrawer}
      sx={getToggleButtonStyles(theme, drawerExpanded)}
      data-cy="action-toggle-menu"
    >
      <ChevronLeftIcon
        color="inherit"
        sx={{
          position: 'relative',
          fontSize: '1.6rem',
          color: 'text.secondary',
        }}
        data-cy="action-toggle-menu-icon"
      />
    </Box>
  );
};

const DrawerContent = ({ drawerExpanded, toggleDrawer }: DrawerProps) => {
  const handleClick = () => toggleDrawer();

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        overflow: 'hidden',
        backgroundColor: 'white',
      }}
    >
      <ToggleButton toggleDrawer={handleClick} drawerExpanded={drawerExpanded} />
      <DrawerHead drawerExpanded={drawerExpanded} />
      <DrawerMenu drawerExpanded={drawerExpanded} />
      <DrawerBottom drawerExpanded={drawerExpanded} />
    </Box>
  );
};

export default DrawerContent;
