import React from 'react';
import { useTranslation } from 'react-i18next';
import { User } from 'src/@types/DataTypes';
import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import { UserAvatar } from 'src/components/Structure';
import { useTheme } from '@mui/material/styles';
import { roles } from 'src/constants/domainData';

interface UsersTableProps {
  users: User[];
  handleSendReminder: (id: string) => void;
  handleEditRole: (id: string, role: string) => void;
  handleDeleteUser: (id: string) => void;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const UsersTable = ({
  users,
  handleSendReminder,
  handleEditRole,
  handleDeleteUser,
}: UsersTableProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [scopedUserId, setScopedUserId] = React.useState<string | null>(null);

  const handleChangeRole = (event: SelectChangeEvent) => {
    handleEditRole(scopedUserId as string, event.target.value);
    setScopedUserId(null);
  };

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableCell>{t('routes.inviteUsers.sections.members.tableColumns.name')}</TableCell>
          <TableCell>{t('routes.inviteUsers.sections.members.tableColumns.position')}</TableCell>
          <TableCell>{t('routes.inviteUsers.sections.members.tableColumns.role')}</TableCell>
          <TableCell>{t('routes.inviteUsers.sections.members.tableColumns.status')}</TableCell>
          <TableCell>{t('routes.inviteUsers.sections.members.tableColumns.actions')}</TableCell>
          <TableCell></TableCell>
        </TableHead>
        <TableBody data-cy="user-table-body">
          {users.map((user: User, index: number) => {
            return (
              <TableRow key={index}>
                <TableCell key={`${index}-user`}>
                  <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <UserAvatar user={user} />
                    <Box ml={2}>
                      <Typography variant="body2">{user.firstName}</Typography>
                      <Typography color="text.secondary" variant="subtitle2">
                        {user.email}
                      </Typography>
                    </Box>
                  </Box>
                </TableCell>
                <TableCell key={`${index}-position`}>{user.position}</TableCell>
                <TableCell key={`${index}-role`} sx={{ width: '136px' }}>
                  {scopedUserId === user.id ? (
                    <Select size="small" value={user.role} onChange={handleChangeRole}>
                      {roles.map((role: string, index: number) => {
                        return (
                          <MenuItem key={index} value={role} sx={{ justifyContent: 'flex-start' }}>
                            {role}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  ) : (
                    user.role
                  )}
                </TableCell>
                <TableCell key={`${index}-isVerified`}>
                  {user?.isVerified
                    ? t('routes.inviteUsers.sections.members.invite.isVerified')
                    : t('routes.inviteUsers.sections.members.invite.isNotVerified')}
                </TableCell>
                <TableCell key={`${index}-action`}>
                  {user?.isVerified ? (
                    t('routes.inviteUsers.sections.members.invite.actionNotNeeded')
                  ) : (
                    <Button
                      data-cy="send-reminder-button"
                      onClick={() => handleSendReminder(user.id)}
                    >
                      {t('routes.inviteUsers.sections.members.invite.action')}
                    </Button>
                  )}
                </TableCell>
                <TableCell key={`${index}-actions`}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'flex-end',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <IconButton
                      sx={{
                        color: scopedUserId === user.id ? 'text.secondary' : 'primary.main',
                        border: theme.custom.border,
                        mr: theme.spacing(0.5),
                      }}
                      onClick={() => {
                        scopedUserId === user.id ? setScopedUserId(null) : setScopedUserId(user.id);
                      }}
                    >
                      {scopedUserId ? <ClearIcon color="inherit" /> : <EditIcon color="inherit" />}
                    </IconButton>
                    <IconButton
                      sx={{
                        color: 'text.secondary',
                        border: theme.custom.border,
                        ml: theme.spacing(0.5),
                      }}
                      onClick={() => handleDeleteUser(user.id)}
                    >
                      <DeleteIcon color="inherit" />
                    </IconButton>
                  </Box>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default UsersTable;
