import React, { useEffect, useState } from 'react';
import { ApolloClient, ApolloProvider as OriginalApolloProvider } from '@apollo/client';
import initializeApolloClient from './client';
import { Firebase } from 'src/components/Contexts';
import { Loader } from 'src/components/Base';

type ApolloProviderProps = {
  children: React.ReactNode;
  firebase: Firebase;
  graphqlEndpoint: string;
};

const ApolloProvider = ({
  children,
  firebase,
  graphqlEndpoint,
}: ApolloProviderProps): JSX.Element => {
  const [apolloClient, setApolloClient] = useState<ApolloClient<unknown> | undefined>(undefined);
  const hasClient = !!apolloClient;

  useEffect(() => {
    if (hasClient) return;

    async function init() {
      // wait for store to by hydrated from localStorage
      const client = await initializeApolloClient({
        firebase,
        uri: graphqlEndpoint,
      });
      setApolloClient(client);
    }

    init();
  }, [hasClient, firebase, graphqlEndpoint]);
  if (!apolloClient) return <Loader />;

  return <OriginalApolloProvider client={apolloClient}>{children}</OriginalApolloProvider>;
};

export default ApolloProvider;
