import React from 'react';
import { Box, Grid, MenuItem, Typography } from '@mui/material';

import { useTheme } from '@mui/material/styles';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import { useTranslation } from 'react-i18next';
import { getCompanyInfoSchema } from 'src/constants/schemas';
import { industries, numberOfEmployees } from 'src/constants/domainData';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CompanyInfo = ({ handleSubmit, formikRef }: { handleSubmit: any; formikRef: any }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const companyInfoSchema = getCompanyInfoSchema(t);

  return (
    <Box
      sx={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        minHeight: '400px',
      }}
    >
      <Formik
        innerRef={formikRef}
        initialValues={companyInfoSchema.getDefault()}
        validateOnBlur={true}
        validateOnChange={true}
        validationSchema={companyInfoSchema}
        onSubmit={async (values, { setSubmitting }) => {
          await handleSubmit(values);
          setSubmitting(false);
        }}
      >
        {({ values }) => (
          <Form data-cy="company-form">
            <Grid container spacing={1}>
              <Grid item xs={12} pb={2}>
                <Typography variant="h6" sx={{ textAlign: 'start' }} data-cy="page-title">
                  {t('welcomeSlides.companyForm.formTitle')}
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{ paddingBottom: theme.spacing(1) }}>
                <Field
                  fullWidth
                  component={TextField}
                  type="text"
                  label={t('welcomeSlides.companyForm.nameLabel')}
                  name="name"
                  data-cy="name"
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sx={{ paddingBottom: theme.spacing(1) }}>
                <Field
                  fullWidth
                  component={TextField}
                  type="text"
                  label={t('welcomeSlides.companyForm.headquartersLabel')}
                  name="headquarters"
                  data-cy="headquarters"
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sx={{ paddingBottom: theme.spacing(1) }}>
                <Field
                  fullWidth
                  component={TextField}
                  style={{
                    width: '100%',
                  }}
                  type="text"
                  align="left"
                  select={true}
                  label={t('welcomeSlides.companyForm.industryLabel')}
                  name="industryId"
                  data-cy="industryId"
                  variant="outlined"
                  size="small"
                >
                  {industries.map(({ id, name }, index) => {
                    return (
                      <MenuItem key={index} value={id}>
                        {name}
                      </MenuItem>
                    );
                  })}
                </Field>
              </Grid>
              <Grid item xs={12} sx={{ paddingBottom: theme.spacing(1) }}>
                <Field
                  fullWidth
                  component={TextField}
                  style={{
                    width: '100%',
                  }}
                  type="text"
                  align="left"
                  select={true}
                  label={t('welcomeSlides.companyForm.subIndustryLabel')}
                  name="subIndustryId"
                  data-cy="subIndustryId"
                  variant="outlined"
                  size="small"
                  disabled={!values['industryId']}
                >
                  {values['industryId'] ? (
                    industries
                      .find(industry => industry.id === values['industryId'])
                      ?.subIndustries.map(({ id, name }, index) => {
                        return (
                          <MenuItem key={index} value={id}>
                            {name}
                          </MenuItem>
                        );
                      })
                  ) : (
                    <MenuItem />
                  )}
                </Field>
              </Grid>

              <Grid item xs={12} sx={{ paddingBottom: theme.spacing(1) }}>
                <Field
                  fullWidth
                  type="text"
                  align="left"
                  component={TextField}
                  select={true}
                  label={t('welcomeSlides.companyForm.numberOfEmployeesLabel')}
                  name="employeesNum"
                  data-cy="employeesNum"
                  variant="outlined"
                  size="small"
                >
                  {numberOfEmployees.map(({ label, value }, index) => {
                    return (
                      <MenuItem key={index} value={value}>
                        {label}
                      </MenuItem>
                    );
                  })}
                </Field>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default CompanyInfo;
