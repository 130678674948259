import { ReactComponent as Controlling } from 'src/assets/svgs/navigation/controlling.svg';
import { ReactComponent as DataManagement } from 'src/assets/svgs/navigation/data_management.svg';
import { ReactComponent as ESG } from 'src/assets/svgs/navigation/esg.svg';
import { ReactComponent as Help } from 'src/assets/svgs/navigation/help.svg';
import { ReactComponent as Home } from 'src/assets/svgs/navigation/home.svg';
import { ReactComponent as Logout } from 'src/assets/svgs/navigation/logout.svg';
import { ReactComponent as Materiality } from 'src/assets/svgs/navigation/materiality.svg';
import { ReactComponent as Report } from 'src/assets/svgs/navigation/report.svg';
import { ReactComponent as Settings } from 'src/assets/svgs/navigation/settings.svg';

export const getMainNavigation = () => {
  return [
    {
      label: 'navigation.drawer.home',
      to: '/',
      Icon: Home,
    },
    {
      label: 'navigation.drawer.esg',
      to: '/esg',
      Icon: ESG,
    },
    {
      label: 'navigation.drawer.materiality',
      to: '/materiality',
      Icon: Materiality,
    },
    {
      label: 'navigation.drawer.dataManagement',
      to: '/data-management',
      Icon: DataManagement,
    },
    {
      label: 'navigation.drawer.controlling',
      to: '/controlling',
      Icon: Controlling,
    },
    {
      label: 'navigation.drawer.reporting',
      to: '/reporting',
      Icon: Report,
    },
  ];
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getBottomNavigation = (actions: any[] | undefined = undefined) => {
  return [
    {
      label: 'navigation.drawer.settings',
      handleClick: actions && actions.length > 0 ? actions[0] : null,
      Icon: Settings,
      dataCy: 'action-settings',
    },
    {
      label: 'navigation.drawer.help',
      Icon: Help,
      handleClick: actions && actions.length > 1 ? actions[1] : null,
      dataCy: 'action-help',
    },
    {
      label: 'navigation.drawer.logout',
      Icon: Logout,
      handleClick: actions && actions.length > 2 ? actions[2] : null,
      dataCy: 'action-logout',
    },
  ];
};
