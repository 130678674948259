import { gql } from '@apollo/client';

export const GET_INITIAL_STATE = gql`
  query GetInitialState {
    drawerOpen @client
    userEmail @client
  }
`;

export const GET_RESET_STATE = gql`
  query GetResetState {
    drawerOpen @client
    userEmail @client
  }
`;

export const GET_DRAWER_STATE_CLIENT = gql`
  query GetDrawerState {
    drawerOpen @client
  }
`;
