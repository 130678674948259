import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SEND_PASSWORD_RESET_MAIL } from 'src/graphql/mutations';
import { AuthLayout } from 'src/components/Auth';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import PasswordForgotForm from 'src/components/Auth/PasswordForgotForm';
import { useBackendErrorMessage, useMessages } from 'src/components/hooks';

const PasswordForgot = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { setSuccessMessage } = useMessages();
  const setBackendError = useBackendErrorMessage();
  const [mailSent, setMailSent] = useState<boolean | null>(false);

  const [resetMail] = useMutation(SEND_PASSWORD_RESET_MAIL, {
    onError: error => {
      setBackendError(error?.message, t('auth.passwordForgot.mailError'));
      setMailSent(false);
    },
    onCompleted: () => {
      setSuccessMessage(t('auth.passwordForgot.mailSuccess'));
      setMailSent(true);
    },
    fetchPolicy: 'no-cache',
  });

  const handleEmailSubmit = async ({ email }: { email: string }) => {
    await resetMail({ variables: { email } });
  };

  const routeLogin = () => {
    setMailSent(false);
    navigate('/login');
  };

  return (
    <AuthLayout title={t('auth.passwordForgot.title')} showSlideshow>
      <PasswordForgotForm
        onSubmit={handleEmailSubmit}
        onRouteChange={routeLogin}
        success={mailSent as boolean}
      />
    </AuthLayout>
  );
};

export default PasswordForgot;
