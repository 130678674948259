import React from 'react';
import confirmation from 'src/assets/svgs/auth/confirmation.svg';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CenteredOverlayedContent } from 'src/components/Base';

const MailConfirmation: React.FC = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <CenteredOverlayedContent title={'auth.registration.confirmation.title'}>
      <>
        <img
          style={{
            width: '254px',
            margin: '32px',
          }}
          src={confirmation}
          alt="Confirmation mail sent"
        />
        <Typography variant="h3">{t('auth.registration.confirmation.title')}</Typography>
        <Box pt={2}>
          <Typography variant="subtitle1" sx={{ color: 'text.secondary' }}>
            {t('auth.registration.confirmation.subtitle')}
          </Typography>
        </Box>
      </>
    </CenteredOverlayedContent>
  );
};

export default MailConfirmation;
