import React, { useState } from 'react';
import { GenericDialog, Loader } from 'src/components/Base';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getIubendaTermsAndConditions } from 'src/utils/iubendaService';

const iubendaApiKey = (process.env.REACT_APP_IUBENDA_API_KEY as string) || undefined;
// Disable if cypress is running (ci mode)
const isCiMode = (process.env.REACT_APP_CI_MODE as string) === 'true';

const TermsConditionsDialog = ({
  setTermsOpen,
  termsOpen,
}: {
  termsOpen: boolean;
  setTermsOpen: (arg0: boolean) => void;
}) => {
  const { t } = useTranslation();
  const [terms, setTerms] = useState('');

  if (iubendaApiKey && !isCiMode) {
    getIubendaTermsAndConditions().then(data => {
      setTerms(data);
    });
  }

  return (
    <GenericDialog
      title={t('termsAndConditions.title')}
      width="100%"
      isOpen={termsOpen}
      handleClose={() => setTermsOpen(false)}
    >
      {terms ? (
        <Box
          sx={{
            '& h1': {
              fontSize: '1.875rem',
            },
            '& h2': {
              fontSize: '1.75rem',
            },
            '& h3': {
              fontSize: '1.5rem',
            },
            '& h4': {
              fontSize: '1.25rem',
            },
            '& p': {
              fontSize: '1rem',
            },
            '& li': {
              fontSize: '1rem',
            },
          }}
          dangerouslySetInnerHTML={{ __html: terms }}
        />
      ) : (
        <Loader />
      )}
    </GenericDialog>
  );
};

export default TermsConditionsDialog;
