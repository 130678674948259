import React from 'react';
import { PageContainer, PageSection, PageTitle, ErrorState } from 'src/components/Structure';
import { useTranslation } from 'react-i18next';
import { useMessages, usePageTitle } from 'src/components/hooks';
import { Grid } from '@mui/material';
import { InviteUserForm } from 'src/components/Settings/InviteUsers';
import UsersTable from 'src/components/Settings/InviteUsers/UsersTable';
import { CompanyUsers } from 'src/@types/DataTypes';
import { useMutation, useQuery } from '@apollo/client';
import { GET_USERS } from 'src/graphql/queries';
import { Loader } from 'src/components/Base';
import { CHANGE_USER_ROLE, DELETE_USER, INVITE_USERS, REINVITE_USER } from 'src/graphql/mutations';
import findIndex from 'lodash/findIndex';
import update from 'immutability-helper';

const InviteUsers: React.FC = (): JSX.Element => {
  const { t } = useTranslation();
  const { setHelmetTitle } = usePageTitle();
  setHelmetTitle(t('routes.inviteUsers.helmet'));
  const { setErrorMessage, setSuccessMessage } = useMessages();
  const { data: companyUsersData, loading: loadingCompanyUsersData, error } = useQuery(GET_USERS);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const companyUsers = companyUsersData?.companyUsers?.edges?.map((edge: any) => edge.node);
  const [inviteUsers] = useMutation(INVITE_USERS, {
    onCompleted: () => {
      setSuccessMessage(t('routes.inviteUsers.messages.inviteSuccess'));
    },
    onError: error => {
      setErrorMessage(t('routes.inviteUsers.messages.inviteError'));
    },
    update: (
      cache,
      {
        data: {
          inviteUsers: { companyUsers },
        },
      }
    ) => {
      const data = cache.readQuery<CompanyUsers>({
        query: GET_USERS,
      });

      cache.writeQuery({
        query: GET_USERS,
        data: {
          companyUsers: {
            edges: data ? [...data.companyUsers.edges, ...companyUsers.edges] : companyUsers.edges,
          },
        },
      });
    },
  });

  const [deleteUser] = useMutation(DELETE_USER, {
    onCompleted: () => setSuccessMessage(t('routes.inviteUsers.messages.deleteSuccess')),
    onError: () => {
      setErrorMessage(t('routes.inviteUsers.messages.deleteError'));
    },
    update: (
      cache,
      {
        data: {
          deleteUser: { id },
        },
      }
    ) => {
      const data = cache.readQuery<CompanyUsers>({
        query: GET_USERS,
      });

      const index = findIndex((data as CompanyUsers).companyUsers.edges, ['node.id', id]);

      cache.writeQuery({
        query: GET_USERS,
        data: {
          companyUsers: {
            edges: update((data as CompanyUsers).companyUsers.edges, {
              $splice: [[index, 1]],
            }),
          },
        },
      });
    },
  });

  const [reinviteUser] = useMutation(REINVITE_USER, {
    onCompleted: () => {
      setSuccessMessage(t('routes.inviteUsers.messages.reinviteSuccess'));
    },
    onError: () => {
      setErrorMessage(t('routes.inviteUsers.messages.reinviteError'));
    },
  });

  const [changeUserRole] = useMutation(CHANGE_USER_ROLE, {
    onCompleted: () => {
      setSuccessMessage(t('routes.inviteUsers.messages.changeRoleSuccess'));
    },
    onError: () => {
      setErrorMessage(t('routes.inviteUsers.messages.changeRoleError'));
    },
  });

  const handleInviteUsers = async (values: Record<string, string>) => {
    await inviteUsers({
      variables: {
        users: values.teamMembers,
      },
    });
  };

  const handleDeleteUser = async (id: string) => {
    await deleteUser({
      variables: {
        id,
      },
    });
  };

  const handleSendReminder = (id: string) => {
    return reinviteUser({
      variables: {
        id,
      },
    });
  };

  const handleEditRole = (id: string, role: string) => {
    return changeUserRole({
      variables: {
        id,
        role,
      },
    });
  };

  if (error) {
    return (
      <ErrorState
        error={t('routes.inviteUsers.errorTitle')}
        errorSubtitle={t('routes.inviteUsers.errorMessage')}
        to="/"
        label={t('pageNotFound.linkText')}
      />
    );
  }

  if (!companyUsersData && loadingCompanyUsersData) {
    return <Loader />;
  }

  return (
    <PageContainer>
      <PageTitle
        title={t('routes.inviteUsers.title')}
        subtitle={t('routes.inviteUsers.subtitle')}
        showDivider
      />
      <PageSection title={t('routes.inviteUsers.sections.invite.title')} spacing={0} showDivider>
        <Grid item xs={12}>
          <InviteUserForm handleSubmit={handleInviteUsers} />
        </Grid>
      </PageSection>
      <PageSection title={t('routes.inviteUsers.sections.members.title')}>
        <Grid item xs={12}>
          <UsersTable
            users={companyUsers}
            handleSendReminder={handleSendReminder}
            handleEditRole={handleEditRole}
            handleDeleteUser={handleDeleteUser}
          />
        </Grid>
      </PageSection>
    </PageContainer>
  );
};

export default InviteUsers;
