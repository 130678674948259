import App from './App';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Firebase, FirebaseProvider, MessageProvider } from './components/Contexts';
import ApolloProvider from './graphql/ApolloProvider';
import { ConfigProvider } from 'src/config';
import { CssBaseline, ThemeProvider } from '@mui/material';
import theme from 'src/styles/theme';
import { getI18Next } from 'src/i18next/i18n';

// Mock Firebase Auth
const useFirebaseAuthMock = process.env.REACT_APP_FIREBASE_USE_EMULATOR as string;
const firebase = new Firebase(useFirebaseAuthMock === 'true');

// Get GraphQL endpoint from environment variable
const graphqlEndpoint = process.env.REACT_APP_GQL_ENDPOINT as string;

// Initialise internationalisation
const useI18NextCiMode = process.env.REACT_APP_CI_MODE as string;
// eslint-disable-next-line
const i18n = getI18Next(useI18NextCiMode === 'true');

// @ts-ignore
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <ConfigProvider>
    <ThemeProvider theme={theme}>
      <FirebaseProvider firebase={firebase}>
        <ApolloProvider firebase={firebase} graphqlEndpoint={graphqlEndpoint}>
          <MessageProvider>
            <CssBaseline />
            <App />
          </MessageProvider>
        </ApolloProvider>
      </FirebaseProvider>
    </ThemeProvider>
  </ConfigProvider>
);
