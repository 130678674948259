import { cachePersistor } from 'src/graphql/client';
import { EDIT_USER, REGISTER_INVITED_USER, REGISTER_USER } from 'src/graphql/mutations';
import { GET_USER_LOGIN_DETAILS } from 'src/graphql/queries';
import {
  signInWithEmailAndPassword,
  signInWithPopup,
  updatePassword as firebaseUpdatePassword,
  updateEmail as firebaseUpdateEmail,
  reauthenticateWithCredential,
  createUserWithEmailAndPassword,
  signInWithCustomToken,
  signOut,
  OAuthProvider,
} from 'firebase/auth';
import { postConsentForUser } from 'src/utils/iubendaService';
import { throwError } from 'src/utils/errorHandling';

const authResolvers = {
  Query: {},
  Mutation: {
    login: async (
      _: void,
      { password, email }: { password: string; email: string },
      // eslint-disable-next-line
      { client: { defaultOptions, query, cache } }: any
    ) => {
      const auth = defaultOptions.firebase.getAuth();
      try {
        await signInWithEmailAndPassword(auth, email, password);
        const token = await auth.currentUser.getIdToken();
        const { data } = await query({
          query: GET_USER_LOGIN_DETAILS,
          context: { headers: { authorization: `Bearer ${token}` } },
        });

        await cache.writeQuery({
          query: GET_USER_LOGIN_DETAILS,
          data,
        });

        // eslint-disable-next-line
      } catch (error: any) {
        throwError(error);
      }
    },
    loginViaMicrosoft: async (
      _: void,
      __: void,
      // eslint-disable-next-line
      { client: { defaultOptions, query, cache } }: any
    ) => {
      const auth = defaultOptions.firebase.getAuth();
      try {
        const microsoftProvider = new OAuthProvider('microsoft.com');
        await signInWithPopup(auth, microsoftProvider);

        const token = await auth.currentUser.getIdToken();

        const { data } = await query({
          query: GET_USER_LOGIN_DETAILS,
          context: { headers: { authorization: `Bearer ${token}` } },
        });

        await cache.writeQuery({
          query: GET_USER_LOGIN_DETAILS,
          data,
        });
        // eslint-disable-next-line
      } catch (error: any) {
        throwError(error);
      }
    },
    // eslint-disable-next-line
    loginViaToken: async (
      _: void,
      { token }: { token: string },
      // eslint-disable-next-line
      { client: { defaultOptions, mutate, query, cache } }: any
    ) => {
      try {
        const auth = defaultOptions.firebase.getAuth();
        await signInWithCustomToken(auth, token);

        const context = { headers: { authorization: `Bearer ${token}` } };
        const {
          data: {
            updateUser: { user: updatedUser },
          },
        } = await mutate({
          mutation: EDIT_USER,
          variables: { input: { isVerified: true } },
          context,
        });

        const {
          data: { company },
        } = await query({
          query: GET_USER_LOGIN_DETAILS,
          context: { headers: { authorization: `Bearer ${token}` } },
        });

        await cache.writeQuery({
          query: GET_USER_LOGIN_DETAILS,
          data: {
            company,
            user: updatedUser,
          },
        });
      } catch (error) {
        throwError(error);
      }
    },
    logout: async (
      _: void,
      __: void,
      // eslint-disable-next-line
      { client }: any
    ) => {
      const auth = client.defaultOptions.firebase.getAuth();
      // Sign out of Firebase
      await signOut(auth);
      // Clear cache
      await cachePersistor.purge();
      // Clear Apollo store
      await client.clearStore();
    },
    registerViaPassword: async (
      _: void,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      {
        email,
        password,
        termsAccepted,
      }: { email: string; password: string; termsAccepted: boolean },
      // eslint-disable-next-line
      { client }: any
    ) => {
      const auth = client.defaultOptions.firebase.getAuth();
      try {
        // Create user
        await createUserWithEmailAndPassword(auth, email as string, password);
        // eslint-disable-next-line

        // Accept terms and conditions
        await postConsentForUser(email);

        // Get token
        const token = await auth.currentUser.getIdToken();
        await client.mutate({
          mutation: REGISTER_USER,
          variables: { email: email, isVerified: false },
          context: { headers: { authorization: `Bearer ${token}` } },
        });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        throwError(error);
      }
    },
    registerViaMicrosoft: async (
      _: void,
      __: void,
      // eslint-disable-next-line
      { client: { defaultOptions, mutate, query, cache } }: any
    ) => {
      const auth = defaultOptions.firebase.getAuth();

      try {
        const microsoftProvider = new OAuthProvider('microsoft.com');
        await signInWithPopup(auth, microsoftProvider);

        // Get token
        const token = await auth.currentUser.getIdToken();

        // Register user
        await mutate({
          mutation: REGISTER_USER,
          variables: { email: auth.currentUser.email, isVerified: true },
          context: { headers: { authorization: `Bearer ${token}` } },
        });

        // Get login details
        const { data } = await query({
          query: GET_USER_LOGIN_DETAILS,
          context: { headers: { authorization: `Bearer ${token}` } },
        });

        // Write login details to cache
        await cache.writeQuery({
          query: GET_USER_LOGIN_DETAILS,
          data,
        });

        // eslint-disable-next-line
      } catch (error: any) {
        throwError(error);
      }
    },
    registerInvited: async (
      _: void,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      { email, password, token }: { email: string; password: string; token: string },
      // eslint-disable-next-line
      { client: { defaultOptions, mutate, query, cache } }: any
    ) => {
      const auth = defaultOptions.firebase.getAuth();

      try {
        await signInWithCustomToken(auth, token);
        // Set password
        await firebaseUpdatePassword(auth.currentUser, password);
        await firebaseUpdateEmail(auth.currentUser, email);

        // Post consent
        await postConsentForUser(auth.currentUser.email);

        await mutate({
          mutation: REGISTER_INVITED_USER,
          variables: { email: auth.currentUser.email, isVerified: false },
          context: { headers: { authorization: `Bearer ${token}` } },
        });

        /* ToDo: Uncomment and remove @client directive once possible.

        // Get login details
        const { data } = await query({
          query: GET_USER_LOGIN_DETAILS,
          context: { headers: { authorization: `Bearer ${token}` } },
        });

        // Write login details to cache
        await cache.writeQuery({
          query: GET_USER_LOGIN_DETAILS,
          data,
        });


        */

        // eslint-disable-next-line
      } catch (error: any) {
        throwError(error);
      }
    },
    verifyInvitedUser: async (
      _: void,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      { input }: { input: Record<string, any> },
      // eslint-disable-next-line
      { client }: any
    ) => {
      // ToDo: Implement
      // eslint-disable-next-line
      console.log('REGISTERING INVITED USER');
      // eslint-disable-next-line
      console.log(input);
    },
    updatePassword: async (
      _: void,
      { oldPassword, newPassword }: { oldPassword: string; newPassword: string },
      // eslint-disable-next-line
      { client }: any
    ) => {
      // Get user and auth
      const auth = client.defaultOptions.firebase.getAuth();
      const user = auth.currentUser;

      try {
        // Reauthenticate user with old password
        const credential = auth.EmailAuthProvider.credential(user.email, oldPassword);
        await reauthenticateWithCredential(user, credential);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        throwError(error);
      }

      try {
        // Set new password
        await firebaseUpdatePassword(user, newPassword);
        // eslint-disable-next-line
      } catch (error: any) {
        throwError(error);
      }
    },
  },
};
export default authResolvers;
