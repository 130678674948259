import React, { createContext, useEffect, useState } from 'react';
import Firebase from './Firebase';
import { fetchAndActivate, getAll } from 'firebase/remote-config';

interface FirebaseContextInterface {
  authenticated: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  flags: Record<string, any>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const FirebaseContext = createContext<FirebaseContextInterface | Record<string, any>>({});
const FirebaseConsumer = FirebaseContext.Consumer;

const defaultConfig = {
  // e.g. underMaintenance: null,
};

const determineFlagType = (value: string) => {
  if (value === 'true') return true;
  if (value === 'false') return false;

  return value.toString();
};

type FirebaseProviderProps = {
  children: React.ReactNode;
  firebase: Firebase;
};

const FirebaseProvider = ({ children, firebase }: FirebaseProviderProps) => {
  const remoteConfig = firebase.getRemoteConfig();
  const [flags, setFlags] = useState(defaultConfig);
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    remoteConfig.defaultConfig = flags;
    remoteConfig.settings.minimumFetchIntervalMillis = 0;

    const fetchFlags = async () => {
      await fetchAndActivate(remoteConfig);
      const remoteFlags = await getAll(remoteConfig);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const newFlags: Record<string, any> = {};

      Object.entries(remoteFlags).forEach(([key, config]) => {
        newFlags[key] = determineFlagType(config.asString());
      });

      setFlags(newFlags);
    };

    fetchFlags();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    firebase.getAuth().onAuthStateChanged(user => {
      setAuthenticated(!!user);
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <></>;

  return (
    <FirebaseContext.Provider
      value={{
        authenticated,
        flags,
      }}
    >
      {children}
    </FirebaseContext.Provider>
  );
};

export { FirebaseProvider, FirebaseConsumer };

export default FirebaseContext;
