import React from 'react';
import { useTranslation } from 'react-i18next';
import { FastField as Field, FieldArray, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import { LoadingButton } from 'src/components/Forms';
import { Grid, Box, Button, InputLabel, MenuItem, InputAdornment, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { getInviteUserSchema } from 'src/constants/schemas';
import { roles, positions } from 'src/constants/domainData';
import { EmailOutlined } from '@mui/icons-material';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const InviteUserForm = ({ handleSubmit }: { handleSubmit: any }) => {
  const { t } = useTranslation();
  const userSchema = getInviteUserSchema(t);
  const initialValues = {
    teamMembers: [
      {
        email: '',
        position: '',
        role: '',
      },
    ],
  };
  return (
    <Formik
      initialValues={initialValues}
      validateOnBlur={false}
      validateOnChange={true}
      validationSchema={userSchema}
      onSubmit={async (values, { setSubmitting }) => {
        await handleSubmit(values);
        setSubmitting(false);
      }}
    >
      {({ values, isSubmitting }) => (
        <Form data-cy="invite-users-form">
          <FieldArray
            name="teamMembers"
            render={arrayHelpers => (
              <Grid container>
                {values.teamMembers.map((teamMember, index) => {
                  return (
                    <Grid container columnSpacing={3} key={index}>
                      <Grid item xs={6} mb={3}>
                        <>
                          {index === 0 && (
                            <Typography variant="body2" mb={1}>
                              {t('routes.inviteUsers.sections.invite.fieldNames.email')}
                            </Typography>
                          )}
                          <Field
                            component={TextField}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <EmailOutlined />
                                </InputAdornment>
                              ),
                            }}
                            fullWidth
                            type="text"
                            data-cy="email"
                            placeholder={t(
                              'routes.inviteUsers.sections.invite.fieldNames.emailPlaceholder'
                            )}
                            name={`teamMembers[${index}][email]`}
                            variant="outlined"
                            size="medium"
                          />
                        </>
                      </Grid>
                      <Grid item xs={3} mb={3}>
                        <>
                          {index === 0 && (
                            <Typography variant="body2" mb={1}>
                              {t('routes.inviteUsers.sections.invite.fieldNames.position')}
                            </Typography>
                          )}
                          <Field
                            type="text"
                            align="left"
                            component={TextField}
                            select={true}
                            placeholder={t(
                              'routes.inviteUsers.sections.invite.fieldNames.position'
                            )}
                            name={`teamMembers[${index}][position]`}
                            data-cy="position"
                            variant="outlined"
                            fullWidth
                          >
                            {positions.map(({ label, value }, index) => {
                              return (
                                <MenuItem key={index} value={value}>
                                  {label}
                                </MenuItem>
                              );
                            })}
                          </Field>
                        </>
                      </Grid>
                      <Grid item xs={3} mb={3}>
                        <>
                          {index === 0 && (
                            <Typography variant="body2" mb={1}>
                              {t('routes.inviteUsers.sections.invite.fieldNames.rolePlaceholder')}
                            </Typography>
                          )}
                          <Field
                            type="text"
                            align="left"
                            component={TextField}
                            select={true}
                            name={`teamMembers[${index}][role]`}
                            data-cy="role"
                            variant="outlined"
                            fullWidth
                          >
                            {roles.map((role, index) => {
                              return (
                                <MenuItem key={index} value={role}>
                                  {role}
                                </MenuItem>
                              );
                            })}
                          </Field>
                        </>
                      </Grid>
                    </Grid>
                  );
                })}
                <Grid item xs={2} mt={2}>
                  <Button
                    fullWidth
                    color="inherit"
                    variant="text"
                    size="medium"
                    startIcon={<AddIcon />}
                    sx={{
                      color: 'text.grey',
                      backgroundColor: 'transparent',
                      height: '46px',
                      justifyContent: 'flex-start',
                    }}
                    onClick={() => arrayHelpers.push(initialValues.teamMembers[0])}
                  >
                    <InputLabel sx={{ color: 'text.grey' }}>
                      {t('routes.inviteUsers.sections.invite.secondaryButtonText')}
                    </InputLabel>
                  </Button>
                </Grid>
                <Grid item xs={8} mt={2}></Grid>
                <Grid item xs={2} mt={2}>
                  <Box display="flex" alignItems="center">
                    <LoadingButton
                      loading={isSubmitting}
                      fullWidth
                      size="medium"
                      variant="contained"
                      color="primary"
                      type="submit"
                      data-cy="submit-button"
                    >
                      <InputLabel sx={{ color: 'white' }}>
                        {t('routes.inviteUsers.sections.invite.primaryButtonText')}
                      </InputLabel>
                    </LoadingButton>
                  </Box>
                </Grid>
              </Grid>
            )}
          />
        </Form>
      )}
    </Formik>
  );
};
export default InviteUserForm;
