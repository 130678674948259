import React, { useState } from 'react';
import { TypedDocumentNode, useMutation } from '@apollo/client';
import { Box, Grid, Typography, useTheme } from '@mui/material';
import { useMessages } from 'src/components/hooks';
import { LoadingButton } from 'src/components/Forms';
import { ErrorState } from 'src/components/Structure';
import { useTranslation } from 'react-i18next';
import { RESEND_USER_VERIFICATION_MAIL } from 'src/graphql/mutations';

declare interface ResendProps {
  email: string;
  title: string;
  subtitle: string;
  buttonText: string;
  mutation: TypedDocumentNode;
}

const ResendVerification = ({ email, title, subtitle, buttonText, mutation }: ResendProps) => {
  const { setErrorMessage } = useMessages();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const theme = useTheme();

  const [resentVerificationMail, setResentVerificationMail] = useState(false);

  const [resendVerificationEmail] = useMutation(RESEND_USER_VERIFICATION_MAIL, {
    variables: { email },
    onError: error => {
      setErrorMessage(error.message);
      setLoading(false);
      // ToDo Clemens: Remove once backend response is fixed
      setResentVerificationMail(true);
    },
    onCompleted: () => {
      setError(null);
      setLoading(false);
      setResentVerificationMail(true);
    },
    fetchPolicy: 'no-cache',
  });

  const handleResendVerification = async () => {
    setLoading(true);
    await resendVerificationEmail();
  };

  if (error)
    return <ErrorState error={error} to="/login" label={t('auth.registration.loginReferral')} />;

  if (!resentVerificationMail) {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h4">{title}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1" sx={{ color: 'text.secondary' }}>
            {subtitle}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <LoadingButton onClick={handleResendVerification} loading={loading} fullWidth>
            {buttonText}
          </LoadingButton>
        </Grid>
      </Grid>
    );
  }

  return (
    <Box mt={12}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h4">
            {t('auth.registration.verificationForm.expired.confirmationTitle')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="body2"
            sx={{
              color: theme.palette.text.secondary,
              paddingBottom: theme.spacing(2),
            }}
          >
            {t('auth.registration.verificationForm.expired.confirmationSubtitle')} {`${email}`}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ResendVerification;
