import React from 'react';
import { useMutation } from '@apollo/client';
import { BooleanParam, useQueryParam } from 'use-query-params';
import { useTranslation } from 'react-i18next';
import { REGISTER_VIA_PASSWORD, REGISTER_VIA_MICROSOFT } from 'src/graphql/mutations';
import { AuthLayout, MailConfirmation, RegistrationForm } from 'src/components/Auth';
import { useBackendErrorMessage, useMessages } from 'src/components/hooks';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export interface ErrnoException extends Error {
  errno?: number;
  code?: string;
  path?: string;
  syscall?: string;
  stack?: string;
}

const Register = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [signedUp, setSignedUp] = useQueryParam('registered', BooleanParam);

  const { setSuccessMessage } = useMessages();
  const setBackendError = useBackendErrorMessage();

  const [registerViaPassword] = useMutation(REGISTER_VIA_PASSWORD, {
    onError: error => {
      setBackendError(error.message, t('auth.registration.registrationError'));
    },
    onCompleted: () => {
      setSuccessMessage(t('auth.registration.registrationSuccess'));
      setSignedUp(true);
    },
    fetchPolicy: 'no-cache',
  });

  const [registerViaMicrosoft, { loading: loadingMicrosoft }] = useMutation(
    REGISTER_VIA_MICROSOFT,
    {
      onError: error => {
        setBackendError(error.message, t('auth.registration.registrationError'));
      },
      onCompleted: () => {
        setSuccessMessage(t('auth.registration.registrationSuccess'));
        navigate('/');
      },
      fetchPolicy: 'no-cache',
    }
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit = (values: Record<string, any>) => {
    return registerViaPassword({ variables: values });
  };

  const onSubmitMicrosoft = () => {
    return registerViaMicrosoft();
  };

  return (
    <>
      {signedUp ? (
        <MailConfirmation />
      ) : (
        <AuthLayout title={t('auth.registration.title')} showSlideshow={!signedUp}>
          <Box mt={5}>
            <RegistrationForm
              onSubmit={onSubmit}
              onSubmitMicrosoft={onSubmitMicrosoft}
              isSubmittingMicrosoft={loadingMicrosoft}
            />
          </Box>
        </AuthLayout>
      )}
    </>
  );
};

export default Register;
