import { Button, ButtonProps, CircularProgress, InputLabel, useTheme } from '@mui/material';
import { styled, Theme } from '@mui/material';
import React, { useEffect } from 'react';

interface VisibilityProps {
  buttoncolor: string;
  visible: number;
  theme: Theme;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ProgressContainer = styled('div')<VisibilityProps>(({ visible, theme, buttoncolor }) => ({
  position: 'absolute',
  marginLeft: 'auto',
  marginRight: 'auto',
  left: '0',
  right: '0',
  opacity: 0,
  transform: 'translateY(30px)',
  transition: 'transform 0.125s ease-in',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  '& > *': {
    color:
      buttoncolor === 'primary'
        ? theme.palette.primary.contrastText
        : theme.palette.secondary.contrastText,
  },

  ...(visible && {
    opacity: 1,
    transform: 'translateY(0)',
  }),
}));

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Label = styled(InputLabel)<VisibilityProps>(({ visible, theme, buttoncolor }) => ({
  transition: 'all 0.125s ease-in',
  opacity: 1,
  transform: 'translateY(0)',
  lineHeight: 1.5,
  cursor: 'pointer',

  color:
    buttoncolor === 'primary'
      ? theme.palette.primary.contrastText
      : theme.palette.secondary.contrastText,

  ...(!visible && {
    opacity: 0,
    transform: 'translateY(-30px)',
  }),
}));

interface Props {
  children: React.ReactNode;
  loading?: boolean;
  disabled?: boolean;
  onClick?: () => void;
}

const LoadingButton: React.FC<Props & ButtonProps> = ({
  children,
  loading,
  disabled,
  onClick,
  type,
  color = 'primary',
  variant = 'contained',
  ...rest
}) => {
  const theme = useTheme();

  const triggerSubmit = (event: KeyboardEvent) => {
    if (type === 'submit' && event.key === 'Enter' && typeof onClick === 'function') {
      // @ts-ignore eslint-disable-next-line
      onClick(event);
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', triggerSubmit);

    return () => window.removeEventListener('keydown', triggerSubmit);
  }, []); // eslint-disable-line
  return (
    <Button
      sx={{
        overflow: 'hidden',
        color: `${theme.palette.primary.contrastText} !important`,
        padding: rest.size === 'small' ? '0.5rem 1rem' : '0.75rem 1.5rem',
        borderRadius: '5px',
        cursor: 'pointer',
      }}
      type={type}
      onClick={onClick}
      disabled={loading || disabled}
      color={color}
      variant={variant}
      {...rest}
    >
      <ProgressContainer visible={loading ? 1 : 0} theme={theme} buttoncolor={color as string}>
        <CircularProgress size={16} thickness={5} />
      </ProgressContainer>
      <Label
        visible={!loading ? 1 : 0}
        theme={theme}
        buttoncolor={color as string}
        size={rest.size === 'small' ? 'small' : 'normal'}
      >
        {children}
      </Label>
    </Button>
  );
};

export default LoadingButton;
