import * as Yup from 'yup';
import { TFunction } from 'react-i18next';

export const getRegistrationSchema = (t: TFunction) => {
  return Yup.object().shape({
    email: Yup.string()
      .default('')
      .email(t('fieldWarnings.emailWarning'))
      .required(t('fieldWarnings.requiredWarning')),
    password: Yup.string()
      .default('')
      .required(t('fieldWarnings.requiredWarning'))
      .min(6, t('fieldWarnings.characterMinimumWarning')),
    termsAccepted: Yup.bool()
      .default(false)
      .oneOf([true], t('fieldWarnings.termsWarning'))
      .required(t('fieldWarnings.termsWarning')),
  });
};

export const getLoginSchema = (t: TFunction) => {
  return Yup.object().shape({
    email: Yup.string()
      .default('')
      .email(t('fieldWarnings.emailWarning'))
      .required(t('fieldWarnings.requiredWarning')),
    password: Yup.string().default('').required(t('fieldWarnings.requiredWarning')),
    rememberUser: Yup.boolean().default(false),
  });
};

export const getPasswordResetSchema = (t: TFunction) => {
  return Yup.object().shape({
    oldPassword: Yup.string()
      .min(6, t('fieldWarnings.characterMinimumWarning'))
      .required(t('fieldWarnings.requiredWarning'))
      .default(''),
    newPassword: Yup.string()
      .min(6, t('fieldWarnings.characterMinimumWarning'))
      .required(t('fieldWarnings.requiredWarning'))
      .default(''),
  });
};

export const getPasswordForgotSchema = (t: TFunction) => {
  return Yup.object().shape({
    email: Yup.string()
      .email(t('fieldWarnings.emailWarning'))
      .required(t('fieldWarnings.requiredWarning')),
  });
};
