import React from 'react';
import { Dialog, Grow, Link, Typography } from '@mui/material';
import WelcomeSlidesCarousel from './WelcomeSlidesCarousel';
import { AuthLayout } from 'src/components/Auth';
import { Box } from '@mui/system';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { TransitionProps } from '@mui/material/transitions';
import { CenteredOverlayedContent } from 'src/components/Base';
import welcomeCompleted from 'src/assets/svgs/welcome_completed.svg';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Grow ref={ref} {...props} in={true} />;
});

const WelcomeSlidesContainer = ({
  isDialogOpen,
  handleCloseWelcomeSlides,
}: {
  isDialogOpen: boolean;
  handleCloseWelcomeSlides: () => void;
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const transitionDurations = {
    enter: theme.transitions.duration.standard as number,
    exit: theme.transitions.duration.leavingScreen as number,
  };

  if (isDialogOpen) {
    return (
      <Dialog
        fullScreen
        open={true}
        TransitionComponent={Transition}
        transitionDuration={transitionDurations}
      >
        <CenteredOverlayedContent title={t('welcomeSlides.pageTitle')}>
          <>
            <img
              style={{
                width: '254px',
                margin: '32px',
              }}
              src={welcomeCompleted}
              alt="Welcome Completed"
            />
            <Typography variant="h3">{t('welcomeSlides.successDialog.title')}</Typography>
            <Box pt={2}>
              <Typography variant="subtitle1" sx={{ color: 'text.secondary' }}>
                <Link
                  data-cy="redirect-link"
                  onClick={handleCloseWelcomeSlides}
                  color="primary"
                  sx={{
                    textDecoration: 'none',
                    cursor: 'pointer',
                  }}
                >
                  <Box component="span" sx={{ color: 'primary.main' }}>
                    {' ' + t('welcomeSlides.successDialog.linkText')}
                  </Box>
                </Link>
                {t('welcomeSlides.successDialog.linkTextSuffix')}
              </Typography>
            </Box>
          </>
        </CenteredOverlayedContent>
      </Dialog>
    );
  }

  return (
    <AuthLayout
      title={t('welcomeSlides.pageTitle')}
      showGreyBackground
      showDashboard
      smallTopPadding
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: '1',
          width: '100%',
          height: '100%',
        }}
      >
        <WelcomeSlidesCarousel />
      </Box>
    </AuthLayout>
  );
};

export default WelcomeSlidesContainer;
