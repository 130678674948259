import React from 'react';
import { FastField as Field, useFormikContext } from 'formik';
import { CheckboxField } from 'src/components/Forms';
import { CheckboxProps } from 'src/components/Auth/AuthInterfaces';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

const AcceptTermsCheckbox = ({ name = 'termsAccepted', handleOpenTerms }: CheckboxProps) => {
  const { values, setFieldValue } = useFormikContext();
  const { t } = useTranslation();
  return (
    <Field
      component={CheckboxField}
      name={name}
      fullWidth
      inverted
      childComponent={
        <>
          <Box
            component="span"
            sx={{
              cursor: 'pointer',
              fontFamily: 'Inter',
              fontSize: '1rem',
              fontWeight: 500,
              color: 'text.secondary',
            }}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onClick={() => setFieldValue(name, !(values as Record<string, any>)[name])}
          >
            {t('auth.terms.prefix')}
          </Box>
          <Box
            component="span"
            sx={{
              cursor: 'pointer',
              fontFamily: 'Inter',
              fontSize: '1rem',
              fontWeight: 600,
              color: 'text.secondary',
              '&:hover': {
                textDecoration: 'underline',
              },
            }}
            onClick={handleOpenTerms}
          >
            {t('auth.terms.terms')}
          </Box>
          <Box
            component="span"
            sx={{
              cursor: 'pointer',
              fontFamily: 'Inter',
              fontSize: '1rem',
              fontWeight: 500,
              color: 'text.secondary',
            }}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onClick={() => setFieldValue(name, !(values as Record<string, any>)[name])}
          >
            {t('auth.terms.suffix')}
          </Box>
        </>
      }
    />
  );
};

export default AcceptTermsCheckbox;
