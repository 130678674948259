import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Grid, Link, Typography, useTheme, SvgIcon, Box } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import { useTranslation } from 'react-i18next';
import { CheckboxField, LoadingButton } from 'src/components/Forms';
import { getLoginSchema } from 'src/constants/schemas';

interface LoginFormProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSubmit: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSubmitMicrosoft: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  isSubmittingMicrosoft: any;
  prefilledEmail?: string;
}

const LoginForm = ({
  onSubmit,
  prefilledEmail,
  onSubmitMicrosoft,
  isSubmittingMicrosoft,
}: LoginFormProps) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const loginSchema = getLoginSchema(t);
  const initialValues = loginSchema.getDefault();

  if (prefilledEmail) {
    initialValues.email = prefilledEmail;
    initialValues.rememberUser = true;
  }

  return (
    <Formik
      initialValues={initialValues}
      validateOnBlur={false}
      validationSchema={loginSchema}
      onSubmit={values => onSubmit(values)}
    >
      {({ isSubmitting }) => (
        <Form>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h4">{t('auth.login.title')}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body2"
                sx={{
                  color: 'text.secondary',
                  paddingBottom: theme.spacing(2),
                }}
              >
                {t('auth.login.subtitle')}
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ paddingBottom: theme.spacing(1) }}>
              <Field
                fullWidth
                component={TextField}
                type="text"
                label={t('auth.login.emailLabel')}
                name="email"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                fullWidth
                component={TextField}
                label={t('auth.login.passwordLabel')}
                type="password"
                name="password"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                component={CheckboxField}
                label={t('auth.login.rememberMeLabel')}
                name="rememberUser"
                childComponent={
                  <Box
                    component="span"
                    sx={{
                      cursor: 'pointer',
                      fontFamily: 'Inter',
                      fontSize: '1rem',
                      fontWeight: 600,
                      color: 'text.secondary',
                    }}
                  >
                    {t('auth.login.rememberMeLabel')}
                  </Box>
                }
              />
            </Grid>
            <Grid item xs={12}>
              <LoadingButton
                fullWidth
                loading={isSubmitting}
                size="small"
                variant="contained"
                color="primary"
                type="submit"
              >
                {t('auth.login.buttonText')}
              </LoadingButton>
            </Grid>
            <Grid item xs={12}>
              <Link
                component={RouterLink}
                to="/password-forgot"
                color="primary"
                sx={{
                  textDecoration: 'none',
                  cursor: 'pointer',
                }}
              >
                <Typography variant="body2">{t('auth.login.passwordForgotReferral')}</Typography>
              </Link>
            </Grid>

            <Grid item xs={12}>
              <LoadingButton
                fullWidth
                loading={isSubmittingMicrosoft}
                onClick={onSubmitMicrosoft}
                size="small"
                variant="contained"
                color="primary"
                startIcon={
                  <SvgIcon>
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12 24H24V13.2H12V24ZM0 24H10.8V13.2H0V24ZM12 12H24V0H12V12ZM0 12H10.8V0H0V12Z"
                      fill="#F9FAFC"
                    />
                  </SvgIcon>
                }
              >
                {t('auth.login.buttonTextMicrosoft')}
              </LoadingButton>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default LoginForm;
