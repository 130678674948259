import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SvgIcon,
} from '@mui/material';
import { getListItemStyles } from 'src/components/Drawer/styles';
import { getBottomNavigation } from 'src/constants/drawerMenuItems';
import { useTheme } from '@mui/material/styles';
import { useBackendErrorMessage } from 'src/components/hooks';
import { LOGOUT } from 'src/graphql/mutations';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import useSupport from 'src/components/hooks/useSupport';

const DrawerBottom = ({ drawerExpanded }: { drawerExpanded: boolean }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const setBackendError = useBackendErrorMessage();
  const { handleOpenSupport } = useSupport();
  const { t } = useTranslation();

  const [logout] = useMutation(LOGOUT, {
    onError: error => {
      setBackendError(
        error.message,
        'We are experiencing issues logging you out. Please try again or contact our support.'
      );
    },
    onCompleted: () => {
      const redirectPath = '/login';
      navigate(redirectPath);
    },
    fetchPolicy: 'no-cache',
  });

  const handleClickSettings = () => navigate('/settings');
  const handleClickHelp = handleOpenSupport;
  const handleClickLogout = () => logout();

  const menuItems = getBottomNavigation([handleClickSettings, handleClickHelp, handleClickLogout]);
  return (
    <Box
      sx={{
        display: 'flex',
        marginTop: 'auto',
        flexDirection: 'column',
        marginBottom: '32px',
      }}
    >
      <List>
        {menuItems.map(
          (
            {
              Icon,
              label,
              handleClick,
              dataCy,
            }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
            { Icon: any; label: string; handleClick: any; dataCy: string },
            index
          ) => {
            return (
              <ListItem
                key={index}
                sx={{
                  display: 'flex',
                  py: '4px',
                }}
              >
                <ListItemButton
                  sx={getListItemStyles(theme, drawerExpanded)}
                  onClick={handleClick}
                  data-cy={dataCy}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      justifyContent: 'center',
                    }}
                  >
                    <SvgIcon component={Icon} inheritViewBox sx={{ fontSize: 60 }} />
                  </ListItemIcon>
                  <ListItemText disableTypography sx={{ opacity: drawerExpanded ? 1 : 0 }}>
                    {t(label)}
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            );
          }
        )}
      </List>
    </Box>
  );
};

export default DrawerBottom;
