import { NestedObject } from 'src/@types/GenericTypes';

class Config {
  fireBaseConfig: NestedObject;

  constructor(config: NestedObject) {
    Object.assign(this, config);

    this.fireBaseConfig = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG as string);
  }
}

export default Config;
