import React, { createContext, useCallback, useState } from 'react';
import { Helmet } from 'react-helmet';

interface HeadContextInterface {
  setHelmetTitle: (title: string) => void;
}

const HeadContext = createContext<HeadContextInterface>({
  setHelmetTitle: () => null,
});
const HeadProvider = ({ children }: { children: React.ReactNode }) => {
  const [title, setTitle] = useState<string>('');

  const setHelmetTitle = useCallback(
    (title: string) => {
      setTitle(title);
    },
    [setTitle]
  );

  return (
    <HeadContext.Provider
      value={{
        setHelmetTitle,
      }}
    >
      <Helmet>
        <title>
          {title ? `${title} - ` : ''}
          mavue
        </title>
      </Helmet>
      {children}
    </HeadContext.Provider>
  );
};

export { HeadProvider };

export default HeadContext;
