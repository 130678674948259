import { gql } from '@apollo/client';

// ToDo: Include department once BE is ready
export const USER_FRAGMENT = gql`
  fragment userValues on User {
    id
    firstName
    lastName
    email
    role
    position
    mobileNumber
    createdTimestamp
    isVerified
    welcomeSlidesCompleted
    fullName @client
    isAdmin @client
  }
`;
