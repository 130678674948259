import { gql } from '@apollo/client';

export const LOGIN = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) @client
  }
`;

export const LOGIN_VIA_MICROSOFT = gql`
  mutation LoginViaMicrosoft {
    loginViaMicrosoft @client
  }
`;

export const LOGIN_VIA_TOKEN = gql`
  mutation LoginViaToken($token: String!) {
    loginViaToken(token: $token) @client
  }
`;

export const REGISTER_INVITED = gql`
  mutation RegisterInvited($token: String!, $password: String!, $email: String!) {
    registerInvited(token: $token, password: $password, email: $email) @client
  }
`;

export const LOGOUT = gql`
  mutation Logout {
    logout @client
  }
`;

export const UPDATE_PASSWORD = gql`
  mutation UpdatePassword($oldPassword: String!, $newPassword: String!) {
    updatePassword(oldPassword: $oldPassword, newPassword: $newPassword) @client
  }
`;

export const SEND_PASSWORD_RESET_MAIL = gql`
  mutation SendPasswordResetEmail($email: String!) {
    sendPasswordResetEmail(email: $email)
  }
`;

export const RESEND_USER_VERIFICATION_MAIL = gql`
  mutation ResendVerificationEmail($email: String!) {
    resendVerificationEmail(email: $email)
  }
`;

export const RESEND_INVITE_MAIL = gql`
  mutation ResendInviteEmail($email: String!) {
    resendInviteEmail(email: $email)
  }
`;
