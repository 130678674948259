import React from 'react';
import { PageContainer, PageTitle } from 'src/components/Structure';
import { useTranslation } from 'react-i18next';
import { usePageTitle } from 'src/components/hooks';

const Controlling: React.FC = (): JSX.Element => {
  const { t } = useTranslation();
  const { setHelmetTitle } = usePageTitle();
  setHelmetTitle(t('navigation.drawer.controlling'));

  return (
    <PageContainer>
      <PageTitle title={t('routes.controlling.title')} />
    </PageContainer>
  );
};

export default Controlling;
