import { gql } from '@apollo/client';

const typeDefs = gql`
  scalar Void
  scalar DateTime

  type Query {
    _empty: String
  }

  type Mutation {
    _empty: String
  }

  enum UserRole {
    ADMIN
    LEAD
    EMPLOYEE
    EXTERNAL
  }

  extend type Query {
    user: User!
  }

  extend type Mutation {
    registerUser(email: String!, isVerified: Boolean! = false): Void
    sendPasswordResetEmail(email: String!): Void
    resendVerificationEmail(email: String!): Void
    updateUser(input: UpdateUserInput!): UpdateUserOutput! @auth
    updateUserByAdmin(id: UUID!, input: UpdateUserByAdminInput!): UpdateUserOutput!
    deleteUserByAdmin(id: UUID!): UUID!
    completeWelcomeSlides: CompleteWelcomeSlidesOutput! @auth
  }

  enum UserPosition {
    EXECUTIVE
    SENIOR_MANAGER
    MANAGER
    ASSOCIATE
    ANALYST
    SPECIALIST
    OTHER
  }

  type UserDepartment {
    id: String!
    name: String!
  }

  type User {
    id: String!
    email: String!
    firstName: String
    lastName: String
    mobileNumber: String
    role: UserRole
    department: UserDepartment!
    position: UserPosition!
    isVerified: Boolean!
    welcomeSlidesCompleted: Boolean!
    createdTimestamp: DateTime!
  }

  type UpdateUserInput {
    email: String
    firstName: String
    lastName: String
    mobileNumber: String
    isVerified: Boolean
    position: UserPosition
    departmentId: UUID
  }

  extend type Query {
    company: Company!
  }

  extend type Mutation {
    updateCompany(input: UpdateCompanyInput!): UpdateCompanyOutput!
  }

  enum EmployeesNum {
    N_1_10
    N_11_50
    N_51_200
    N_201_500
    N_501_1000
    N_1001_10000
  }

  type Industry {
    id: String!
    name: String!
  }

  type SubIndustry {
    id: String!
    name: String!
  }

  type UpdateCompanyInput {
    name: String
    dashboardUrl: String
    headquarters: String
    employeesNum: EmployeesNum
    subIndustryId: UUID
  }

  type Company {
    id: String!
    name: String!
    dashboardUrl: String!
    employeesNum: EmployeesNum!
    industry: Industry!
    subIndustry: SubIndustry!
  }
`;

export default typeDefs;
