export const signupCards = [
  {
    id: 'activities',
    title: 'routes.welcome.sections.start.activities.title',
    to: '/',
    subtitle: 'routes.welcome.sections.start.activities.subtitle',
    text: 'routes.welcome.sections.start.activities.text',
  },

  {
    id: 'facilities',
    title: 'startCards.facilities.title',
    to: '/',
    subtitle: 'routes.welcome.sections.start.facilities.subtitle',
    text: 'routes.welcome.sections.start.facilities.text',
  },

  {
    id: 'esg',
    title: 'routes.welcome.sections.start.esg.title',
    to: '/esg',
    subtitle: 'routes.welcome.sections.start.esg.subtitle',
    text: 'routes.welcome.sections.start.esg.text',
  },
];

export const prioritiesCards = [
  {
    title: 'routes.welcome.sections.priorities.teamMembers.title',
    to: '/welcome/invite-users',
    id: 'teamMembers',
    subtitle: 'routes.welcome.sections.priorities.teamMembers.subtitle',
    text: 'routes.welcome.sections.priorities.teamMembers.text',
  },

  {
    title: 'routes.welcome.sections.priorities.taskManagement.title',
    to: '/task-management',
    id: 'taskManagement',
    subtitle: 'routes.welcome.sections.priorities.taskManagement.subtitle',
    text: 'routes.welcome.sections.priorities.taskManagement.text',
  },

  {
    title: 'routes.welcome.sections.priorities.support.title',
    id: 'support',
    subtitle: 'routes.welcome.sections.priorities.support.subtitle',
    text: 'routes.welcome.sections.priorities.support.text',
  },
];
