import React, { ReactNode } from 'react';
import { Box, useTheme } from '@mui/material';
import { Drawer, DrawerContent } from 'src/components/Drawer';
import { AppBar } from 'src/components/Structure';
import useDrawerState from 'src/components/Drawer/hooks/useDrawerState';

interface Props {
  children?: ReactNode;
}

const Layout: React.FC<Props> = ({ children }): JSX.Element => {
  const { drawerOpen, toggleDrawer } = useDrawerState();
  const theme = useTheme();
  return (
    <Box
      component="div"
      sx={{
        display: 'flex',
      }}
    >
      <Drawer open={drawerOpen}>
        <DrawerContent drawerExpanded={drawerOpen} toggleDrawer={toggleDrawer} />
      </Drawer>
      <Box
        component="span"
        sx={{
          position: 'relative',
          overflow: 'hidden',
          flexGrow: 1,
          minHeight: '100vh',
        }}
      >
        <AppBar />
        <Box
          component="main"
          sx={{
            padding: theme.spacing(0.35, 4, 3, 3),
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default Layout;
