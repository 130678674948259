import React, { useState, createContext, ReactNode } from 'react';
import { GenericDialog } from 'src/components/Base';
import { SupportForm } from 'src/components/Forms';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { useMessages } from 'src/components/hooks';
import { SEND_SUPPORT_MESSAGE } from 'src/graphql/mutations';

interface Props {
  children: ReactNode;
}

interface SupportProps {
  handleOpenSupport: () => void;
}

export const SupportContext = createContext<SupportProps>({
  handleOpenSupport: () => undefined,
});

const SupportProvider: React.FC<Props> = ({ children }) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const { setSuccessMessage, setErrorMessage } = useMessages();

  const [sendMessage, { loading }] = useMutation(SEND_SUPPORT_MESSAGE, {
    onCompleted: () => {
      setSuccessMessage(t('supportDialog.successMessage'));
      setOpen(false);
    },
    onError: () => {
      setErrorMessage(t('supportDialog.errorMessage'));
    },
  });

  const handleOpenSupport = () => {
    setOpen(true);
  };

  const handleCloseSupport = () => {
    setOpen(false);
  };

  const handleSendMessage = async (message: string) => {
    await sendMessage({ variables: { message } });
  };

  return (
    <SupportContext.Provider
      value={{
        handleOpenSupport,
      }}
    >
      <>
        <GenericDialog
          title={t('supportDialog.title')}
          handleClose={handleCloseSupport}
          isOpen={open}
        >
          <SupportForm onSubmit={handleSendMessage} isSubmitting={loading} />
        </GenericDialog>
        {children}
      </>
    </SupportContext.Provider>
  );
};

export { SupportProvider };

export default SupportContext;
