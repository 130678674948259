const errors = {
  // Firebase errors
  'auth/user-not-found': {
    de: 'Nutzer konnte nicht gefunden werden.',
    en: 'User could not be found.',
  },
  'auth/wrong-password': {
    de: 'Passwort ist nicht korrekt.',
    en: 'The password is incorrect.',
  },
  'auth/too-many-requests': {
    de: 'Zu viele Versuche. Bitte später erneut versuchen.',
    en: 'Too many attempts. Please try again later.',
  },
  'auth/email-already-in-use': {
    de: 'Diese Email Adresse wird bereits verwendet.',
    en: 'The email address is already in use by another account.',
  },
  // Backend errors - Defined in https://github.com/mavue-climate/mavue-backend-client/blob/develop/src/common/errors.ts
  USER_EXISTS: {
    de: 'Diese Email Adresse wird bereits verwendet.',
    en: 'The email address is already in use by another account.',
  },
  USER_DOESNT_EXIST: {
    de: 'Diese Email Adresse ist unbekannt.',
    en: 'The email address is unknown.',
  },
  USER_UNAUTHORIZED: {
    de: 'Nutzer ist nicht authorisiert.',
    en: 'User is unauthorized.',
  },
};

export default errors;
