import React, { useRef } from 'react';
import {
  useBackendErrorMessage,
  useMessages,
  useUserData,
  useWelcomeSlides,
} from 'src/components/hooks';
import { Box, Button, Step, InputLabel, StepLabel, Stepper, Typography } from '@mui/material';
import { adminSteps } from 'src/constants/welcomeSlidesAdminSteps';
import { useTranslation } from 'react-i18next';
import { Carousel } from 'react-responsive-carousel';
import { useTheme } from '@mui/material/styles';
import { LoadingButton } from 'src/components/Forms';
import { ArrowForward } from '@mui/icons-material';
import { FormikProps } from 'formik';
import { useMutation } from '@apollo/client';
import { EDIT_COMPANY, EDIT_USER } from 'src/graphql/mutations';
import { UserFormInput, CompanyFormInput } from 'src/@types/DataTypes';
import { intToUuid } from 'src/utils/uuid';
import { GET_USER_LOGIN_DETAILS } from 'src/graphql/queries';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type FormValues = Record<string, any>;

const WelcomeSlidesCarousel = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const profileInfoRef = useRef<FormikProps<FormValues>>(null);
  const companyInfoRef = useRef<FormikProps<FormValues>>(null);
  const { currentSlide, slides, isAdmin, nextSlide, prevSlide } = useWelcomeSlides();
  const containerRef = useRef(null);
  const { setSuccessMessage } = useMessages();
  const setBackendError = useBackendErrorMessage();
  const { user, company } = useUserData();

  const getRef = (index: number) => {
    if (index === 0) return profileInfoRef;
    if (index === 1) return companyInfoRef;
    return null;
  };

  const [editUser] = useMutation(EDIT_USER, {
    onError: error => {
      setBackendError(error.message, t('welcomeSlides.profileForm.submitError'));
    },
    update: (
      cache,
      {
        data: {
          updateUser: { user: updatedUser },
        },
      }
    ) => {
      cache.writeQuery({
        query: GET_USER_LOGIN_DETAILS,
        data: {
          company: company,
          user: updatedUser,
        },
      });
    },
    onCompleted: () => {
      setSuccessMessage(t('welcomeSlides.profileForm.submitSuccess'));
      nextSlide();
    },
    fetchPolicy: 'no-cache',
  });

  const [editCompany] = useMutation(EDIT_COMPANY, {
    onError: error => {
      setBackendError(error.message, t('welcomeSlides.companyForm.submitError'));
    },
    update: (
      cache,
      {
        data: {
          updateCompany: { company: updatedCompany },
        },
      }
    ) => {
      cache.writeQuery({
        query: GET_USER_LOGIN_DETAILS,
        data: {
          company: updatedCompany,
          user: user,
        },
      });
    },
    onCompleted: () => {
      setSuccessMessage(t('welcomeSlides.companyForm.submitSuccess'));
      nextSlide();
    },
    fetchPolicy: 'no-cache',
  });

  const onSubmitUser = async (values: UserFormInput) => {
    return editUser({
      variables: { input: { ...values, departmentId: intToUuid(values.departmentId as number) } },
    });
  };

  const onSubmitCompany = async (values: CompanyFormInput) => {
    delete values.industryId;
    return editCompany({
      variables: {
        input: {
          ...values,
          subIndustryId: intToUuid(values.subIndustryId as number),
        },
      },
    });
  };

  const getOnSubmit = (index: number) => {
    if (index === 0) return onSubmitUser;
    if (index === 1) return onSubmitCompany;

    return null;
  };

  const handleNextSlide = () => {
    if (currentSlide === 0 && profileInfoRef.current) {
      profileInfoRef.current?.handleSubmit();
    } else if (currentSlide === 1 && companyInfoRef.current) {
      companyInfoRef.current?.handleSubmit();
    }
  };

  // @ts-ignore
  return (
    <Box
      ref={containerRef}
      sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}
    >
      {isAdmin && (
        <Box>
          <Stepper
            nonLinear
            activeStep={currentSlide}
            alternativeLabel
            sx={{ marginTop: 2, position: 'relative', left: '-12%', width: '128%' }}
          >
            {adminSteps.map((label, index) => (
              <Step key={label} completed={index < currentSlide}>
                <StepLabel>
                  <Typography sx={{ marginTop: -1 }} variant="subtitle1">
                    {t(label)}
                  </Typography>
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          maxHeight: '100%',
          py: 2,
        }}
      >
        {
          <Carousel
            showArrows={false}
            showThumbs={false}
            showStatus={false}
            showIndicators={false}
            transitionTime={0}
            selectedItem={currentSlide}
          >
            {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              slides.map((Slide: any, index) => (
                <Box
                  key={index}
                  sx={{
                    py: 2,
                    width: '100%',
                    height: '468px',
                  }}
                >
                  <Slide
                    active={index === currentSlide}
                    formikRef={getRef(index)}
                    handleSubmit={getOnSubmit(index)}
                  />
                </Box>
              ))
            }
          </Carousel>
        }
      </Box>
      {currentSlide < slides.length - 1 && (
        <Box sx={{ display: 'flex', flexDirection: 'row', pb: 4 }}>
          {currentSlide > 0 ? (
            <Button
              color="primary"
              variant="outlined"
              disabled={currentSlide === 0}
              onClick={prevSlide}
              size="small"
            >
              <InputLabel sx={{ color: theme.palette.primary.main }}>
                {t('welcomeSlides.backButton')}
              </InputLabel>
            </Button>
          ) : (
            <></>
          )}
          <Box sx={{ flex: '1 1 auto' }} />
          <LoadingButton
            onClick={handleNextSlide}
            loading={
              profileInfoRef.current?.isSubmitting || companyInfoRef.current?.isSubmitting || false
            }
            size="small"
            endIcon={<ArrowForward />}
            data-cy="next-button"
          >
            {currentSlide === slides.length - 1
              ? t('welcomeSlides.finishButton')
              : t('welcomeSlides.nextButton')}
          </LoadingButton>
        </Box>
      )}
    </Box>
  );
};
export default WelcomeSlidesCarousel;
