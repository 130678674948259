const userPolicies = {
  User: {
    fields: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      fullName: (_: any, { readField }: { readField: any }) => {
        const firstName = readField('firstName');
        const lastName = readField('lastName');

        return firstName && lastName ? `${firstName} ${lastName}` : '';
      },
      // eslint-disable-next-line
      isAdmin: (_: any, { readField }: { readField: any }) => {
        return readField('role').toLowerCase() === 'admin';
      },
    },
  },
};

export default userPolicies;
