import { gql } from '@apollo/client';
import { COMPANY_FRAGMENT } from 'src/graphql/fragments';

export const EDIT_COMPANY = gql`
  mutation EditCompany($input: UpdateCompanyInput!) {
    updateCompany(input: $input) {
      company {
        ...companyValues
      }
    }
  }
  ${COMPANY_FRAGMENT}
`;
