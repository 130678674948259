import { useContext, useEffect } from 'react';
import { PosthogContext } from 'src/components/Contexts';

export const useLogEvent = () => {
  return useContext(PosthogContext);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useLogEventOnMount = (event: string, eventProperties: Record<string, any>) => {
  const { captureEvent } = useLogEvent();

  useEffect(() => {
    captureEvent(event, eventProperties);
  }, [captureEvent]); // eslint-disable-line
};
