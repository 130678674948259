import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Grid, Link, Typography, useTheme } from '@mui/material';
import { FastField as Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import { LoadingButton } from 'src/components/Forms';
import { getRegistrationSchema } from 'src/constants/schemas';
import AcceptTermsCheckbox from './AcceptTermsCheckbox';
import { useTranslation } from 'react-i18next';
import { StringParam, useQueryParam } from 'use-query-params';
import { TermsAndConditionsDialog } from 'src/components/Auth/index';

// @ts-ignore eslint-disable-next-line
const RegistrationInvitedForm = ({ onSubmit }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [email] = useQueryParam('email', StringParam);

  // eslint-disable-next-line
  const [termsOpen, setTermsOpen] = useState(false);

  const registrationSchema = getRegistrationSchema(t);
  const initialValues = registrationSchema.getDefault();
  if (email) {
    initialValues.email = email;
  }
  return (
    <>
      <Formik
        initialValues={initialValues}
        validateOnBlur={false}
        validateOnChange={true}
        validationSchema={registrationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          await onSubmit(values);
          setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h4">{t('auth.registration.invitationForm.title')}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="body2"
                  sx={{
                    color: 'text.secondary',
                    paddingBottom: theme.spacing(2),
                  }}
                >
                  {t('auth.registration.invitationForm.subtitle')}
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{ paddingBottom: theme.spacing(1) }}>
                <Field
                  component={TextField}
                  fullWidth
                  type="text"
                  label={t('auth.registration.emailLabel')}
                  name="email"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  component={TextField}
                  fullWidth
                  type="password"
                  name="password"
                  label={t('auth.registration.passwordLabel')}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <AcceptTermsCheckbox handleOpenTerms={() => setTermsOpen(true)} />
              </Grid>
              <Grid item xs={12}>
                <LoadingButton
                  loading={isSubmitting}
                  variant="contained"
                  color="primary"
                  type="submit"
                  fullWidth
                >
                  {t('auth.registration.buttonText')}
                </LoadingButton>
              </Grid>
              <Grid item xs={12}>
                <Link
                  component={RouterLink}
                  to="/login"
                  color="primary"
                  sx={{
                    textDecoration: 'none',
                    cursor: 'pointer',
                  }}
                >
                  <Typography variant="body2">{t('auth.registration.loginReferral')}</Typography>
                </Link>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      <TermsAndConditionsDialog termsOpen={termsOpen} setTermsOpen={setTermsOpen} />
    </>
  );
};

export default RegistrationInvitedForm;
