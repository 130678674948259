import { initializeApp, FirebaseApp } from 'firebase/app';
import { getAuth, connectAuthEmulator, Auth } from 'firebase/auth';
import { RemoteConfig, getRemoteConfig } from 'firebase/remote-config';

const firebaseConfig = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG || '{}');

class Firebase {
  firebase: FirebaseApp;
  auth: Auth;
  config: RemoteConfig;

  constructor(useMock: boolean) {
    const firebase = initializeApp(firebaseConfig);
    const auth = getAuth(firebase);
    const config = getRemoteConfig(firebase);

    if (useMock) {
      // ToDo Clemens: Put constant into configProvider
      connectAuthEmulator(auth, 'http://localhost:9099', {
        disableWarnings: true,
      });
    }

    this.firebase = firebase;
    this.auth = auth;
    this.config = config;
  }

  getAuth = () => this.auth;
  getRemoteConfig = () => this.config;
  getFirebase = () => this.firebase;
}

export default Firebase;
