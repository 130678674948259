import React from 'react';
import { Box, useTheme, AppBar as MuiAppBar, Toolbar, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import { useMessages } from 'src/components/hooks';
import { UserAvatar, LanguageSwitch } from 'src/components/Structure';
import { Breadcrumb } from 'src/components/Base';

const AppBar = (): JSX.Element => {
  const theme = useTheme();
  const { setErrorMessage } = useMessages();

  const handleNotImplemented = () => setErrorMessage('Not implemented yet');

  return (
    <MuiAppBar
      position="relative"
      sx={{
        boxShadow: 'none',
        paddingRight: '8px',
        paddingTop: '16px',
        backgroundColor: 'background.default',
        height: theme.custom.appBarHeight as string,
      }}
    >
      <Toolbar>
        <Box sx={{ flexGrow: 1, display: 'flex' }}>
          <Breadcrumb />
        </Box>
        <Box sx={{ flexGrow: 0, flexDirection: 'row', alignItems: 'center' }}>
          <IconButton
            size="medium"
            aria-label="search"
            disableTouchRipple
            sx={{
              mr: '8px',
            }}
            onClick={handleNotImplemented}
          >
            <SearchIcon />
          </IconButton>
          <LanguageSwitch />
          <IconButton
            size="medium"
            disableTouchRipple
            sx={{
              mr: '16px',
            }}
            onClick={handleNotImplemented}
          >
            <NotificationsNoneOutlinedIcon />
          </IconButton>
        </Box>
        <UserAvatar />
      </Toolbar>
    </MuiAppBar>
  );
};

export default AppBar;
