import { gql } from '@apollo/client';

export const COMPANY_FRAGMENT = gql`
  fragment companyValues on Company {
    id
    name
    dashboardUrl
    industry {
      id
      name
    }
    subIndustry {
      id
      name
    }
    employeesNum
  }
`;
