import { gql } from '@apollo/client';
import { USER_FRAGMENT, COMPANY_FRAGMENT } from 'src/graphql/fragments';

export const GET_USER_DETAILS = gql`
  query GetUserDetails {
    user {
      ...userValues
    }
  }
  ${USER_FRAGMENT}
`;

export const GET_USER_LOGIN_DETAILS = gql`
  query GetUserLoginDetails {
    user {
      ...userValues
    }
    company {
      ...companyValues
    }
  }
  ${USER_FRAGMENT}
  ${COMPANY_FRAGMENT}
`;

export const GET_USERS = gql`
  query GetUsers {
    companyUsers {
      edges {
        cursor
        node {
          ...userValues
        }
      }
    }
  }
  ${USER_FRAGMENT}
`;
