import React from 'react';
import {
  Box,
  SvgIcon,
  ListItem,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { getMainNavigation } from 'src/constants/drawerMenuItems';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { getListItemStyles } from 'src/components/Drawer/styles';
import { useTranslation } from 'react-i18next';
import kebabCase from 'lodash/kebabCase';

const DrawerMenu = ({ drawerExpanded }: { drawerExpanded: boolean }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const menuItems = getMainNavigation();
  const location = useLocation();
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        cursor: 'pointer',
        margin: '32px 12px 32px 2px',
      }}
    >
      <List>
        {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          menuItems.map(({ Icon, label, to }: { Icon: any; label: string; to: string }, index) => {
            return (
              <ListItem
                key={index}
                sx={{
                  display: 'flex',
                  py: '4px',
                }}
              >
                <ListItemButton
                  sx={getListItemStyles(theme, drawerExpanded)}
                  selected={location.pathname === to}
                  onClick={() => navigate(to)}
                  data-cy={`menu-link-${kebabCase(label)}`}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      justifyContent: 'center',
                    }}
                  >
                    <SvgIcon component={Icon} inheritViewBox sx={{ fontSize: 62 }} />
                  </ListItemIcon>
                  <ListItemText disableTypography sx={{ opacity: drawerExpanded ? 1 : 0 }}>
                    {t(label)}
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            );
          })
        }
      </List>
    </Box>
  );
};

export default DrawerMenu;
