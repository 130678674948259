import React from 'react';
import pageNotFound from 'src/assets/svgs/page_not_found.svg';
import { Box, Link, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CenteredOverlayedContent } from 'src/components/Base';

const NotFound: React.FC = (): JSX.Element => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <CenteredOverlayedContent title={t('pageNotFound.title')}>
      <>
        <img
          style={{
            width: '254px',
            margin: '32px',
          }}
          src={pageNotFound}
          alt="Page not found"
        />
        <Typography variant="h3" data-cy="page-title">
          {t('pageNotFound.title')}
        </Typography>
        <Box pt={2}>
          <Typography variant="subtitle1" sx={{ color: 'text.secondary' }}>
            {t('pageNotFound.text')}
            <Link
              onClick={() => navigate('/')}
              color="primary"
              sx={{
                textDecoration: 'none',
                cursor: 'pointer',
              }}
            >
              <Box component="span" sx={{ color: 'primary.main' }}>
                {' ' + t('pageNotFound.linkText')}
              </Box>
            </Link>
          </Typography>
        </Box>
      </>
    </CenteredOverlayedContent>
  );
};

export default NotFound;
