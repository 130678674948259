import React from 'react';
import { Grid, Link, Typography } from '@mui/material';
import { TextField } from 'formik-material-ui';
import { Field, Form, Formik } from 'formik';
import { LoadingButton } from 'src/components/Forms';
import { useTranslation } from 'react-i18next';
import { getPasswordForgotSchema } from 'src/constants/schemas';
import { useTheme } from '@mui/system';

interface PasswordForgotFormProps {
  success: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSubmit: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onRouteChange: any;
}

// @ts-ignore eslint-disable-next-line
const PasswordForgotForm = ({ success, onSubmit, onRouteChange }: PasswordForgotFormProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const passwordForgotSchema = getPasswordForgotSchema(t);
  return (
    <Grid container spacing={3}>
      <Grid item>
        {success ? (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h4">{t('auth.passwordForgot.title')}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body2"
                sx={{
                  color: 'text.secondary',
                  paddingBottom: theme.spacing(2),
                }}
              >
                {t('auth.passwordForgot.successMessage')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Link
                onClick={onRouteChange}
                color="primary"
                sx={{
                  textDecoration: 'none',
                  cursor: 'pointer',
                }}
              >
                <Typography variant="body2">{t('auth.passwordForgot.backToLogin')}</Typography>
              </Link>
            </Grid>
          </Grid>
        ) : (
          <Formik
            initialValues={passwordForgotSchema.getDefault()}
            validateOnBlur={false}
            validationSchema={passwordForgotSchema}
            onSubmit={values => onSubmit(values)}
          >
            {({ isSubmitting }) => (
              <Form>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography variant="h4">{t('auth.passwordForgot.title')}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="body2"
                      sx={{
                        color: 'text.secondary',
                        paddingBottom: theme.spacing(2),
                      }}
                    >
                      {t('auth.passwordForgot.subtitle')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sx={{ paddingBottom: theme.spacing(1) }}>
                    <Field
                      fullWidth
                      component={TextField}
                      type="text"
                      label={t('auth.login.emailLabel')}
                      name="email"
                      variant="outlined"
                      size="medium"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <LoadingButton
                      loading={isSubmitting}
                      variant="contained"
                      color="primary"
                      type="submit"
                      fullWidth
                    >
                      {t('auth.passwordForgot.buttonText')}
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        )}
      </Grid>
    </Grid>
  );
};

export default PasswordForgotForm;
