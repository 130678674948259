import React from 'react';
import { Box, Typography, Divider } from '@mui/material';
import { useTheme } from '@mui/material/styles';

interface PageTitleProps {
  title?: string;
  subtitle?: string;
  showDivider?: boolean;
  showLightTitle?: boolean;
}

const PageTitle = ({ title, subtitle, showDivider, showLightTitle }: PageTitleProps) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        paddingTop: theme.spacing(6),
        paddingLeft: theme.spacing(1),
        justifyContent: 'space-between',
        position: 'relative',
        mt: '8px',
      }}
    >
      <Typography variant="h2" data-cy="page-title" sx={{ fontWeight: showLightTitle ? 400 : 600 }}>
        {title}
      </Typography>
      {subtitle && (
        <Typography variant="body1" sx={{ pt: theme.spacing(1), color: 'text.grey' }}>
          {subtitle}
        </Typography>
      )}
      {showDivider && (
        <Divider
          light
          sx={{ color: 'primary', width: '100%', height: '2px', py: theme.spacing(1) }}
        />
      )}
    </Box>
  );
};
export default PageTitle;
