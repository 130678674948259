import { Shadows, SimplePaletteColorOptions, createTheme } from '@mui/material/styles';
import { BreakpointsOptions } from '@mui/system';
import { TypographyOptions } from '@mui/material/styles/createTypography';
import { alpha, PaletteMode } from '@mui/material';
import {
  ColorPartial,
  CommonColors,
  PaletteColorOptions,
  PaletteTonalOffset,
  TypeAction,
  TypeBackground,
} from '@mui/material/styles/createPalette';

require('./external.css');
require('./fonts.css');

declare module '@mui/material/styles' {
  interface Theme {
    palette: {
      primary: SimplePaletteColorOptions;
      secondary: SimplePaletteColorOptions;
      tertiary: PaletteColorOptions;
      error: SimplePaletteColorOptions;
      info: SimplePaletteColorOptions;
      success: SimplePaletteColorOptions;
      warning: SimplePaletteColorOptions;
      background: {
        default: string;
        paper: string;
        grey: string;
      };
      charts: {
        environment: string[];
        social: string[];
        governance: string[];
      };
      text: {
        primary: string;
        secondary: string;
        tertiary: string;
      };
    };
    typography: TypographyOptions;
    breakpoints: BreakpointsOptions;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    custom: Record<string, any>;
    shadows: Shadows;
  }

  interface PaletteOptions {
    primary?: PaletteColorOptions;
    secondary?: PaletteColorOptions;
    tertiary?: PaletteColorOptions;
    error?: PaletteColorOptions;
    warning?: PaletteColorOptions;
    info?: PaletteColorOptions;
    success?: PaletteColorOptions;
    mode?: PaletteMode;
    tonalOffset?: PaletteTonalOffset;
    contrastThreshold?: number;
    common?: Partial<CommonColors>;
    grey?: ColorPartial;
    // @ts-ignore
    text?: {
      primary: string;
      secondary: string;
      disabled?: string;
      grey: string;
    };
    divider?: string;
    action?: Partial<TypeAction>;
    background?: Partial<TypeBackground>;
    getContrastText?: (background: string) => string;
    charts?: {
      environment: string[];
      social: string[];
      governance: string[];
    };
  }

  interface ThemeOptions {
    palette?: PaletteOptions;
    breakpoints: BreakpointsOptions;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    custom: Record<string, any>;
    shadows?: Shadows;
  }
}

// @ts-ignore
const theme = createTheme({
  palette: {
    primary: {
      main: '#4356ed',
      contrastText: '#fff',
    },
    secondary: {
      main: '#fdc535',
    },
    tertiary: {
      main: '#B5D065',
    },
    info: {
      main: '#2941FF',
    },
    success: {
      main: '#10B981',
    },
    warning: {
      main: '#fdc535',
    },
    error: {
      main: '#FF8080',
    },
    background: {
      default: '#F8F9FB',
      paper: '#CFD1D4',
      // @ts-ignore
      grey: '#D9D6D6',
    },
    text: {
      primary: '#1E1E1E',
      secondary: 'rgba(3,2,41,0.6)',
      // @ts-ignore
      grey: alpha('#030229', 0.6),
    },
    charts: {
      environment: ['#A5D8DD', '#7E909A', '#1C4E80', '#5C95AE', '#4E4E6A', '#D2D6D9', '#97B1C6'],
      social: ['#333652', '#E9EAEC', '#90ADC6', '#FAD02C'],
      governance: ['#7E909A'],
    },
  },
  typography: {
    fontSize: 16,
    fontFamily: ['Inter', 'Questrial', 'Arial', 'sans-serif'].join(','),
    h1: {
      fontFamily: 'Questrial',
      fontSize: '3.75rem',
      fontWeight: 400,
    },
    h2: {
      fontFamily: 'Questrial',
      fontSize: '2.1875rem',
      '@media (min-width:1500px)': {
        fontSize: '2.5rem',
      },
      fontWeight: 400,
    },
    h3: {
      fontFamily: 'Inter',
      fontSize: '2.1875rem',
      '@media (min-width:1500px)': {
        fontSize: '2.5rem',
      },
      fontWeight: 600,
    },
    h4: {
      fontFamily: 'Inter',
      fontSize: '1.5rem',
      '@media (min-width:1500px)': {
        fontSize: '2rem',
      },
      fontWeight: 600,
      lineHeight: 0.7,
    },
    h5: {
      fontFamily: 'Inter',
      fontSize: '1.25rem',
      '@media (min-width:1500px)': {
        fontSize: '1.5rem',
      },
      fontWeight: 600,
    },
    h6: {
      fontFamily: 'Inter',
      fontSize: '1rem',
      '@media (min-width:1500px)': {
        fontSize: '1.25rem',
      },
      fontWeight: 600,
    },
    body1: {
      fontFamily: 'Inter',
      fontSize: '1.25rem',
      '@media (min-width:1500px)': {
        fontSize: '1.25rem',
      },
      fontWeight: 600,
    },
    body2: {
      fontFamily: 'Inter',
      fontSize: '1.125rem',
      '@media (min-width:1500px)': {
        fontSize: '1.125rem',
      },
      fontWeight: 500,
    },
    subtitle1: {
      fontFamily: 'Inter',
      fontSize: '1rem',
      '@media (min-width:1500px)': {
        fontSize: '1rem',
      },
      fontWeight: 600,
    },
    subtitle2: {
      fontFamily: 'Inter',
      fontSize: '0.875rem',
      '@media (min-width:1500px)': {
        fontSize: '0.875rem',
      },
      fontWeight: 600,
    },
    caption: {
      fontFamily: 'Inter',
      fontSize: '0.75rem',
      '@media (min-width:1500px)': {
        fontSize: '0.75rem',
      },
      fontWeight: 600,
    },
    button: {
      fontFamily: 'Inter',
      fontSize: '0.9375rem',
      '@media (min-width:1500px)': {
        fontSize: '0.9375rem',
      },
      fontWeight: 600,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1500,
    },
  },
  custom: {
    drawerWidth: '280px',
    drawerWidthClosed: '96px',
    appBarHeight: '80px',
    border: '1px solid #D9D6D6',
    thinBorder: '0.05px solid #D9D6D6',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.16)',
    backdropBlur: 'blur(5px)',
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          // Smaller than in design specs (56px)
          height: '46px',
          borderRadius: '5px',
        },
        sizeSmall: {
          // Smaller than in design specs (42px)
          height: '38px',
        },
        textSizeSmall: {
          fontSize: '0.875rem',
          fontFamily: 'Inter',
          fontWeight: 600,
        },
        textSizeLarge: {
          fontSize: '0.9375rem',
          fontFamily: 'Inter',
          fontWeight: 600,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: 'text.secondary',
          borderRadius: '10px',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderRadius: '30px',
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: { borderRadius: '10px', border: '1px solid #D9D6D6' },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: '#EDEDED',
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          borderTop: '1px solid #D9D6D6',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.16)',
          borderRadius: '10px',
          border: '1px solid #D90D6D6',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#F8F9FB',
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          fontSize: '1.125rem',
          fontFamily: 'Inter',
          fontWeight: 600,
          height: '50px',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          height: '1.2rem',
          fontSize: '1.125rem',
          fontWeight: 500,
        },
        root: {
          backgroundColor: 'white',
          height: '50px',
        },
        sizeSmall: {
          height: '40px',
          fontSize: '1rem',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& fieldset': {
            borderColor: '#D9D6D6',
            borderWidth: '0.5px',
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          height: '32px',
          fontSize: '1rem',
          color: alpha('#1E1E1E', 0.6),
          fontWeight: 400,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontSize: '0.9375rem',
          fontFamily: 'Inter',
          fontWeight: 600,
          lineHeight: 1.5,
        },
        sizeSmall: {
          fontSize: '0.875rem',
          fontFamily: 'Inter',
          fontWeight: 600,
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          color: 'rgba(3,2,41,0.6)',
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.16)',
          backgroundColor: 'white',
          color: 'rgba(3,2,41,0.6)',
        },
        action: {
          size: '20px',
        },
      },
    },
    MuiAlertTitle: {
      styleOverrides: {
        root: {
          fontSize: '1.25rem',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          marginLeft: '-10px',
        },
      },
    },
    MuiStep: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          height: '4px',
        },
        light: {
          color: '#D9D6D6',
        },
      },
    },
  },
});
// Separate these from the custom object, because we want to keep the original objects as well
const shadows = [
  // e.g. button
  '0 2px 10px 1px rgba(0, 0, 0, 0.1)',
];

theme.shadows.push(...shadows);

export default theme;
