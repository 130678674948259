import axios from 'axios';

const hookURL = process.env.REACT_APP_SLACK_WEBHOOK_URL as string;

export const sendMessageToSlack = async (message: string) => {
  if (hookURL) {
    try {
      await axios.post(
        hookURL,
        JSON.stringify({
          text: message,
        })
      );
    } catch (error) {
      throw error;
    }
  }
};
