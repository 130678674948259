import { generateTestCompanyMembers } from 'src/graphql/mocks/user';

const companyResolvers = {
  Query: {
    companyUsers: async (
      _: void,
      __: void,
      // eslint-disable-next-line
      { client }: any
    ) => {
      return generateTestCompanyMembers();
    },
  },
  Mutation: {},
};

export default companyResolvers;
