import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
} from '@mui/material';
import React, { ReactNode } from 'react';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';

interface DialogProps {
  title: string;
  handleClose?: () => void;
  isOpen: boolean;
  width?: string;
  height?: string;
  useBackdropFilter?: boolean;
  dialogActions?: ReactNode;
  children: ReactNode;
}

const GenericDialog = ({
  title,
  handleClose,
  isOpen,
  width,
  height,
  useBackdropFilter = true,
  dialogActions,
  children,
}: DialogProps) => {
  const theme = useTheme();

  const transitionDurations = {
    enter: theme.transitions.duration.enteringScreen as number,
    exit: theme.transitions.duration.leavingScreen as number,
  };

  return (
    <Dialog
      open={isOpen}
      sx={{
        zIndex: 2000,
        backgroundColor: 'transparent',
        backdropFilter: useBackdropFilter ? theme.custom.backdropBlur : 'none',
        boxShadow: theme.custom.boxShadow,
      }}
      transitionDuration={transitionDurations}
      onBackdropClick={handleClose}
      PaperComponent={Box}
      PaperProps={{
        style: {
          width: width || '570px',
          minHeight: height || '80%',
          boxShadow: theme.custom.boxShadow,
          backgroundColor: 'white',
          border: theme.custom.border,
          borderRadius: '10px',
        },
      }}
    >
      <DialogTitle>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: handleClose ? 'space-between' : 'flex-start',
            alignItems: 'center',
          }}
        >
          <Typography variant="h4" data-cy="dialog-title">
            {title}
          </Typography>
          {handleClose && (
            <IconButton onClick={handleClose} data-cy="close-button">
              <CloseIcon />
            </IconButton>
          )}
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent>{children}</DialogContent>
      {dialogActions && (
        <>
          <Divider />
          <DialogActions>
            <Box px={1.5} py={0.5}>
              {dialogActions}
            </Box>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default GenericDialog;
