import React, { createContext } from 'react';
import posthog from 'posthog-js';
import { useUserData } from 'src/components/hooks';

interface PosthogContextInterface {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  captureEvent: (event: string, properties?: Record<string, any>) => void;
}

const apiKey = (process.env.REACT_APP_POSTHOG_API_KEY as string) || '';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const PosthogContext = createContext<PosthogContextInterface | Record<string, any>>({});

const PosthogProvider = ({ children }: { children: React.ReactNode }) => {
  const {
    // @ts-ignore
    user,
    // @ts-ignore
    company,
  } = useUserData();

  let identity = {};
  if (apiKey) {
    posthog.init(apiKey, { api_host: 'https://app.posthog.com' });

    identity = {
      email: user?.email,
      name: user?.fullName,
      role: user?.role,
      userId: user?.id,
      companyId: company?.id,
      companyName: company?.name,
    };
  }

  if (!apiKey) return <>{children}</>;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const captureEvent = (event: string, properties?: Record<string, any>) => {
    posthog.capture(event, { ...properties, ...identity });
  };

  return (
    <PosthogContext.Provider
      value={{
        captureEvent,
      }}
    >
      {children}
    </PosthogContext.Provider>
  );
};

export { PosthogProvider };

export default PosthogContext;
