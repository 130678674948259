export const numberOfEmployees = [
  { label: '1-10', value: 'N_1_10' },
  { label: '11-50', value: 'N_11_50' },
  { label: '51-200', value: 'N_51_200' },
  { label: '201-500', value: 'N_201_500' },
  { label: '501-1000', value: 'N_501_1000' },
  { label: '1001-10000', value: 'N_1001_10000' },
];

export const positions = [
  { label: 'Executive level', value: 'EXECUTIVE' },
  { label: 'Senior Manager', value: 'SENIOR_MANAGER' },
  { label: 'Management', value: 'MANAGER' },
  { label: 'Associate', value: 'ASSOCIATE' },
  { label: 'Analyst', value: 'ANALYST' },
  { label: 'Specialist', value: 'SPECIALIST' },
  { label: 'Other', value: 'OTHER' },
];

export const roles = ['Admin', 'Lead', 'User', 'External'];

export const departments = [
  { value: 1, label: 'Administration / Operations' },
  { value: 2, label: 'Finance & Accounting' },
  { value: 3, label: 'Sustainability / CSR / ESG' },
  { value: 4, label: 'Research and development' },
  { value: 5, label: 'Production' },
  { value: 6, label: 'Supply chain' },
  { value: 7, label: 'Human resources' },
  { value: 8, label: 'Logistics' },
  { value: 9, label: 'Sales & marketing' },
  { value: 10, label: 'Customer service' },
];

export const industries = [
  {
    id: 1,
    name: 'Accommodation and food service activities',
    subIndustries: [
      { id: 100, name: 'Accommodation' },
      { id: 101, name: 'Food and beverage service activities' },
    ],
  },
  {
    id: 2,
    name: 'Activities of extraterritorial organizations and bodies',
    subIndustries: [{ id: 200, name: 'Activities of extraterritorial organizations and bodies' }],
  },
  {
    id: 3,
    name: 'Activities of households',
    subIndustries: [
      { id: 300, name: 'Activities of households as employers of domestic personnel' },
      {
        id: 301,
        name:
          'Undifferentiated goods and services producing activities' +
          ' of private households for own use',
      },
    ],
  },
  {
    id: 4,
    name: 'Administrative and support service activities',
    subIndustries: [
      { id: 400, name: 'Employment activities' },
      {
        id: 401,
        name: 'Office administrative, office support and other business support activities',
      },
      { id: 402, name: 'Rental and leasing activities' },
      { id: 403, name: 'Security and investigation activities' },
      { id: 404, name: 'Services to buildings and landscape activities' },
      {
        id: 405,
        name: 'Travel agency, tour operator and other reservation service and related activities',
      },
    ],
  },
  {
    id: 5,
    name: 'Agriculture, forestry and fishing',
    subIndustries: [
      { id: 500, name: 'Crop and animal production, hunting and related service activities' },
      { id: 501, name: 'Fishery and aquaculture' },
      { id: 502, name: 'Forestry and logging' },
    ],
  },
  {
    id: 6,
    name: 'Arts, entertainment and recreation',
    subIndustries: [
      { id: 600, name: 'Creative, arts and entertainment activities' },
      { id: 601, name: 'Gambling and betting activities' },
      { id: 602, name: 'Library, archives, museums and other cultural activities' },
      { id: 603, name: 'Sports activities and amusement and recreation activities' },
    ],
  },
  {
    id: 7,
    name: 'Construction',
    subIndustries: [
      { id: 700, name: 'Civil engineering' },
      { id: 701, name: 'Construction of buildings' },
      { id: 702, name: 'Specialized construction activities' },
    ],
  },
  {
    id: 8,
    name: 'Education',
    subIndustries: [{ id: 800, name: 'Education' }],
  },
  {
    id: 9,
    name: 'Electricity, gas, steam and air conditional supply',
    subIndustries: [{ id: 900, name: 'Electricity, gas, steam and air conditional supply' }],
  },
  {
    id: 10,
    name: 'Financial and insurance activites',
    subIndustries: [
      { id: 1000, name: 'Activities auxiliary to financial services and insurance activities' },
      { id: 1001, name: 'Financial service activities, except insurance and pension funding' },
      {
        id: 1002,
        name: 'Insurance, reinsurance and pension funding, except compulsory social security',
      },
    ],
  },
  {
    id: 11,
    name: 'Human health and social work activities',
    subIndustries: [
      { id: 1100, name: 'Human health activities' },
      { id: 1101, name: 'Residential care activities' },
      { id: 1102, name: 'Social work activities without accommodation' },
    ],
  },
  {
    id: 12,
    name: 'Information and communication',
    subIndustries: [
      { id: 1200, name: 'Computer programming, consultancy and related activities' },
      { id: 1201, name: 'Information service activities' },
      {
        id: 1202,
        name:
          'Motion picture, video and television programme production,' +
          ' sound recording and music publishing activities',
      },
      { id: 1203, name: 'Programming and broadcasting activities' },
      { id: 1204, name: 'Publishing activities' },
      { id: 1205, name: 'Telecommunications' },
    ],
  },
  {
    id: 13,
    name: 'Manufacturing',
    subIndustries: [
      { id: 1300, name: 'Manufacture of food products' },
      {
        id: 1301,
        name: 'Manufacture of basic pharmaceutical products and pharmaceutical preparations',
      },
      { id: 1302, name: 'Manufacture of beverages' },
      { id: 1303, name: 'Manufacture of chemicals and chemical products' },
      { id: 1304, name: 'Manufacture of coke and refined petroleum products' },
      { id: 1305, name: 'Manufacture of computer, electronic and optical products' },
      { id: 1306, name: 'Manufacture of electrical equipment' },
      {
        id: 1307,
        name: 'Manufacture of fabricated metal products, except machinery and equipment',
      },
      { id: 1308, name: 'Manufacture of food products' },
      { id: 1309, name: 'Manufacture of furniture' },
      { id: 1310, name: 'Manufacture of leather and related products' },
      { id: 1311, name: 'Manufacture of machinery and equipment' },
      { id: 1312, name: 'Manufacture of motor vehicles, trailers and semi-trailers' },
      { id: 1313, name: 'Manufacture of other non-metallic mineral products' },
      { id: 1314, name: 'Manufacture of other transport' },
      { id: 1315, name: 'Manufacture of paper and paper products' },
      { id: 1316, name: 'Manufacture of rubber and plastic products' },
      { id: 1317, name: 'Manufacture of textiles' },
      { id: 1318, name: 'Manufacture of tobacco products' },
      { id: 1319, name: 'Manufacture of wearing apparel' },
      {
        id: 1320,
        name:
          'Manufacture of wood and of products of wood and cork, except furniture;' +
          ' manufacture of articles of straw and plaiting materials',
      },
      { id: 1321, name: 'Other manufacturing' },
      { id: 1322, name: 'Printing and reproduction of recorded media' },
      { id: 1323, name: 'Repair and installation of machinery and equipment' },
    ],
  },
  {
    id: 14,
    name: 'Mining and quarrying',
    subIndustries: [
      { id: 1400, name: 'Extraction of crude petroleum and natural gas' },
      { id: 1401, name: 'Mining of coal and lignite' },
      { id: 1402, name: 'Mining of metal ores' },
      { id: 1403, name: 'Mining support service activities' },
      { id: 1404, name: 'Other mining and quarrying' },
    ],
  },
  {
    id: 15,
    name: 'Other service activities',
    subIndustries: [
      { id: 1500, name: 'Activities of membership organizations' },
      { id: 1501, name: 'Other personal service activities' },
      { id: 1502, name: 'Repair of computers and personal and household goods' },
    ],
  },
  {
    id: 16,
    name: 'Professional, scientific and technical activities',
    subIndustries: [
      { id: 1600, name: 'Activities of head offices, management consultancy activities' },
      { id: 1601, name: 'Advertising and market research' },
      {
        id: 1602,
        name: 'Architectural and engineering activities; technical testing and analysis',
      },
      { id: 1603, name: 'Legal and accounting activities' },
      { id: 1604, name: 'Other professional, scientific and technical activities' },
      { id: 1605, name: 'Scientific research and development' },
      { id: 1606, name: 'Veterinary activities' },
    ],
  },
  {
    id: 17,
    name: 'Public administration and defense; compulsory social security',
    subIndustries: [
      { id: 1700, name: 'Public administration and defense; compulsory social security' },
    ],
  },
  {
    id: 18,
    name: 'Real estate activities',
    subIndustries: [{ id: 1800, name: 'Real estate activities' }],
  },
  {
    id: 19,
    name: 'Transporting and storage',
    subIndustries: [
      { id: 1900, name: 'Air transport' },
      { id: 1901, name: 'Land transport and transport via pipelines' },
      { id: 1902, name: 'Postal and courier activities' },
      { id: 1903, name: 'Warehousing and support activities for transportation' },
      { id: 1904, name: 'Water transport' },
    ],
  },
  {
    id: 20,
    name: 'Water supply; sewerage; waste management and redemption activities',
    subIndustries: [
      { id: 2000, name: 'Remediation activities and other waste management services' },
      { id: 2001, name: 'Sewerage' },
      { id: 2002, name: 'Waste collection, treatment and disposal activities; materials recovery' },
      { id: 2003, name: 'Water collection, treatment and supply' },
    ],
  },
  {
    id: 21,
    name: 'Wholesale and retail trade; repair of motor vehicles and motor cycles',
    subIndustries: [
      { id: 2100, name: 'Retail trade, except of motor vehicles and motorcycles' },
      {
        id: 2101,
        name: 'Whole sale and retail trade and repair of motor vehicles and motorcycles',
      },
      { id: 2102, name: 'Wholesale trade, except of motor vehicles and motorcycles' },
    ],
  },
];
