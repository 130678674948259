import React, { useState, useEffect } from 'react';
import { Fade } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const FadeMount = ({ children }: { children: React.ReactElement }) => {
  const [fadeIn, setFadeIn] = useState(false);
  const theme = useTheme();

  const timeout = {
    enter: theme.transitions.duration.enteringScreen as number,
    exit: theme.transitions.duration.leavingScreen as number,
  };

  useEffect(() => {
    setFadeIn(true);

    return () => setFadeIn(false);
  }, []); //eslint-disable-line

  return (
    <Fade in={fadeIn} timeout={timeout}>
      {children}
    </Fade>
  );
};

export default FadeMount;
