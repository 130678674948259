import React from 'react';
import { Avatar, Typography } from '@mui/material';
import { useMessages, useUserData } from 'src/components/hooks';
import PersonIcon from '@mui/icons-material/Person';
import { User } from 'src/@types/DataTypes';

interface AvatarProps {
  user?: User;
}

const UserAvatar = ({ user }: AvatarProps): JSX.Element => {
  const { user: currentUser } = useUserData();
  const { setErrorMessage } = useMessages();

  // ToDo implement onClick functionality
  const handleNotImplemented = () => {
    if (!user) {
      setErrorMessage('Not implemented yet');
    }
  };
  const displayedUser = user || currentUser;

  // ToDo Add image avatar support

  if (displayedUser?.firstName && displayedUser?.lastName) {
    const initials = displayedUser.firstName[0] + displayedUser.lastName[0];
    return (
      <Avatar onClick={handleNotImplemented} sx={{ width: '48px', height: '48px' }}>
        <Typography variant="subtitle1">{initials}</Typography>
      </Avatar>
    );
  }

  return (
    <Avatar onClick={handleNotImplemented} sx={{ width: '48px', height: '48px' }}>
      <PersonIcon />
    </Avatar>
  );
};

export default UserAvatar;
