import { useContext } from 'react';
import ConfigContext from 'src/config';

interface ConfigContextInterface {
  minScreenWidth: number;
  mobileScreenWidth: number;
}

const useConfig = () => {
  const state = useContext(ConfigContext);

  // @ts-ignore
  return state.config as ConfigContextInterface;
};

export default useConfig;
