// Utility function that handles error code from graphQL, error code or message.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const throwError = (error: any) => {
  if (error.graphQLErrors && error.graphQLErrors.length > 0) {
    // For backend error we want to return first graphql error extensions code
    throw new Error(error?.graphQLErrors[0]?.extensions?.code);
  } else if (error.code) {
    // For firebase errors, we want to return the code
    throw new Error(error.code);
  } else {
    throw error;
  }
};
