import React from 'react';
import { Breadcrumbs, Link, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { ArrowBack } from '@mui/icons-material';

const Breadcrumb = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const paths = location.pathname.split('/').filter(e => e);

  // Display starting on second navigation level
  if (paths.length < 2) {
    return <></>;
  }

  const handleReroute = (index: number) => {
    const path = '/' + paths.slice(0, index + 1).join('/');
    navigate(path);
  };

  return (
    <>
      <ArrowBack
        sx={{ color: 'primary.main', fontSize: '24px', mr: 1, mt: 0.25, ml: 0.5 }}
        onClick={() => navigate(-1)}
      />
      <Breadcrumbs separator={<KeyboardDoubleArrowRightIcon color="inherit" />}>
        {paths.map((path, index) => {
          return (
            <Link
              key={index}
              onClick={() => handleReroute(index)}
              sx={{ cursor: 'pointer', color: 'text.secondary', textDecoration: 'none' }}
            >
              <Typography variant="subtitle1">{t(`breadcrumbMap.${path}`)}</Typography>
            </Link>
          );
        })}
      </Breadcrumbs>
    </>
  );
};
export default Breadcrumb;
