import { useQuery } from '@apollo/client';
import { GET_USER_LOGIN_DETAILS } from 'src/graphql/queries';
import { Company, User } from 'src/@types/DataTypes';

interface UserDataValues {
  user: User;
  company: Company;
  loadingUserData: boolean;
}

const useUserData = (): UserDataValues => {
  const { data, loading: loadingUserData } = useQuery(GET_USER_LOGIN_DETAILS, {
    fetchPolicy: 'cache-only',
  });

  return {
    user: data?.user as User,
    company: data?.company as Company,
    loadingUserData,
  };
};
export default useUserData;
