import React, { useEffect, useState } from 'react';

import { StringParam, useQueryParam } from 'use-query-params';
import { Navigate, useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { RESEND_USER_VERIFICATION_MAIL, LOGIN_VIA_TOKEN } from 'src/graphql/mutations';
import { useBackendErrorMessage } from 'src/components/hooks';
import { Loader } from 'src/components/Base';
import { AuthLayout, ResendVerification } from 'src/components/Auth';
import { useTranslation } from 'react-i18next';

const TokenizedLogin = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const setBackendError = useBackendErrorMessage();
  const [loginCalled] = useState(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);
  const [token] = useQueryParam('token', StringParam);
  const [email] = useQueryParam('email', StringParam);

  const [login] = useMutation(LOGIN_VIA_TOKEN, {
    onError: error => {
      setBackendError(error.message, t('auth.login.loginError'));
      setHasError(true);
    },
    onCompleted: () => {
      setHasError(false);
      navigate('/');
    },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (token && !loginCalled) {
      setLoading(true);
      login({ variables: { token } }).then(() => setLoading(false));
    }
    // eslint-disable-next-line
  }, [token, loginCalled]);

  if (loading) return <Loader />;

  if (hasError) {
    return (
      <AuthLayout title={t('auth.registration.title')} showSlideshow>
        <ResendVerification
          title={t('auth.login.loginForm.title')}
          subtitle={t('auth.login.loginForm.subtitle')}
          buttonText={t('auth.login.loginForm.buttonText')}
          email={email as string}
          mutation={RESEND_USER_VERIFICATION_MAIL}
        />
      </AuthLayout>
    );
  }

  return <Navigate to="/" />;
};

export default TokenizedLogin;
