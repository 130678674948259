import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Grid, Link, Box, Typography, useTheme, SvgIcon } from '@mui/material';
import { FastField as Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import { LoadingButton } from 'src/components/Forms';
import { getRegistrationSchema } from 'src/constants/schemas';
import AcceptTermsCheckbox from './AcceptTermsCheckbox';
import { useTranslation } from 'react-i18next';
import { TermsAndConditionsDialog } from 'src/components/Auth/index';

// @ts-ignore eslint-disable-next-line
const RegistrationForm = ({ onSubmit, onSubmitMicrosoft, isSubmittingMicrosoft }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [termsOpen, setTermsOpen] = useState(false);
  const { t } = useTranslation();
  const theme = useTheme();

  const registrationSchema = getRegistrationSchema(t);

  return (
    <>
      <Formik
        initialValues={registrationSchema.getDefault()}
        validateOnBlur={false}
        validateOnChange={true}
        validationSchema={registrationSchema}
        onSubmit={values => onSubmit(values)}
      >
        {({ handleSubmit, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h4">{t('auth.registration.title')}</Typography>
              </Grid>
              <Grid item xs={12} sx={{ paddingBottom: theme.spacing(1) }}>
                <Field
                  component={TextField}
                  fullWidth
                  type="text"
                  label={t('auth.registration.emailLabel')}
                  name="email"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  component={TextField}
                  fullWidth
                  type="password"
                  name="password"
                  label={t('auth.registration.passwordLabel')}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <AcceptTermsCheckbox handleOpenTerms={() => setTermsOpen(true)} />
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" alignItems="center">
                  <LoadingButton
                    loading={isSubmitting}
                    fullWidth
                    size="small"
                    variant="contained"
                    color="primary"
                    type="submit"
                  >
                    {t('auth.registration.buttonText')}
                  </LoadingButton>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Link
                  component={RouterLink}
                  to="/login"
                  color="primary"
                  sx={{
                    textDecoration: 'none',
                    cursor: 'pointer',
                  }}
                >
                  <Typography variant="body2">{t('auth.registration.loginReferral')}</Typography>
                </Link>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" alignItems="center">
                  <LoadingButton
                    fullWidth
                    loading={isSubmittingMicrosoft}
                    onClick={onSubmitMicrosoft}
                    size="small"
                    variant="contained"
                    color="primary"
                    startIcon={
                      <SvgIcon>
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M12 24H24V13.2H12V24ZM0 24H10.8V13.2H0V24ZM12 12H24V0H12V12ZM0 12H10.8V0H0V12Z"
                          fill="#F9FAFC"
                        />
                      </SvgIcon>
                    }
                  >
                    {t('auth.registration.buttonTextMicrosoft')}
                  </LoadingButton>
                </Box>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      <TermsAndConditionsDialog termsOpen={termsOpen} setTermsOpen={setTermsOpen} />
    </>
  );
};

export default RegistrationForm;
