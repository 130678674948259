import React from 'react';
import { Link, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ArrowForward } from '@mui/icons-material';

interface LinkItemProps {
  textIdentifier: string;
  to: string;
  color?: string;
  showArrow?: boolean;
  showUnderline?: boolean;
}

const LinkItem = ({
  textIdentifier,
  to,
  color = 'primary',
  showArrow,
  showUnderline,
}: LinkItemProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Link
      onClick={() => navigate(to)}
      color="primary"
      underline={showUnderline ? 'always' : 'none'}
      sx={{
        textDecoration: 'none',
        cursor: 'pointer',
        color: color,
      }}
    >
      <Typography
        variant="body1"
        sx={{ color: color, textDecoration: showUnderline ? 'underline' : 'none' }}
        display="inline"
      >
        {t(textIdentifier)}
        {showArrow && (
          <ArrowForward
            color={'inherit'}
            sx={{
              height: theme.spacing(3),
              marginBottom: theme.spacing(-0.75),
              fontSize: theme.spacing(2.5),
            }}
          />
        )}
      </Typography>
    </Link>
  );
};
export default LinkItem;
