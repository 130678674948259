export function intToUuid(value: number | bigint): string {
  const hex = value.toString(16).padStart(32, '0');
  return [
    hex.slice(0, 8),
    hex.slice(8, 12),
    hex.slice(12, 16),
    hex.slice(16, 20),
    hex.slice(20),
  ].join('-');
}

export function uuidToInt(value: string): bigint {
  return BigInt(`0x${value.replaceAll('-', '')}`);
}
