import React, { ReactElement, createContext, useEffect, useState, Dispatch } from 'react';
import { useUserData } from 'src/components/hooks';
import { WelcomeSlidesContainer } from 'src/components/WelcomeSlides';
import { adminContent, userContent } from 'src/constants/welcomeSlidesContent';
import clamp from 'lodash/clamp';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { BooleanParam, NumberParam, useQueryParam, withDefault } from 'use-query-params';

interface WelcomeSlidesContextProps {
  currentSlide: number;
  setCurrentSlide: Dispatch<number>;
  isAdmin: boolean;
  slides: ReactElement[];
  nextSlide: () => void;
  openSuccessDialog: () => void;
  prevSlide: () => void;
}

const WelcomeSlidesContext = createContext<WelcomeSlidesContextProps>({
  currentSlide: 0,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setCurrentSlide: () => {},
  isAdmin: false,
  slides: [<></>] as ReactElement[],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  nextSlide: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  openSuccessDialog: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  prevSlide: () => {},
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const WelcomeSlidesProvider = ({ children }: { children: any }) => {
  const {
    user: { welcomeSlidesCompleted, isAdmin },
  } = useUserData();
  const navigate = useNavigate();

  const showWelcomeSlides = !welcomeSlidesCompleted;
  const [openWelcomeSlides, setOpenWelcomeSlides] = useQueryParam(
    'showWelcomeSlides',
    withDefault(BooleanParam, showWelcomeSlides)
  );

  const [currentSlide, setCurrentSlide] = useQueryParam('slide', withDefault(NumberParam, 0));

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const slidesContent = isAdmin ? adminContent : userContent;
  const [slides] = useState([...slidesContent]);

  useEffect(() => {
    if (showWelcomeSlides) {
      setOpenWelcomeSlides(showWelcomeSlides);
      navigate('/welcome');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [welcomeSlidesCompleted]);

  const nextSlide = () => {
    setCurrentSlide(clamp(currentSlide + 1, 0, slides.length - 1));
  };

  const prevSlide = () => {
    setCurrentSlide(clamp(currentSlide - 1, 0, slides.length - 1));
  };

  const openSuccessDialog = () => setIsDialogOpen(true);

  const handleCloseWelcomeSlides = () => {
    setOpenWelcomeSlides(false);
  };

  return (
    <WelcomeSlidesContext.Provider
      value={{
        currentSlide,
        setCurrentSlide,
        isAdmin,
        // @ts-ignore
        slides,
        openSuccessDialog,
        nextSlide,
        prevSlide,
      }}
    >
      {openWelcomeSlides ? (
        <Routes>
          <Route
            path="/*"
            element={
              <WelcomeSlidesContainer
                isDialogOpen={isDialogOpen}
                handleCloseWelcomeSlides={handleCloseWelcomeSlides}
              />
            }
          />
        </Routes>
      ) : (
        children
      )}
    </WelcomeSlidesContext.Provider>
  );
};
export { WelcomeSlidesProvider };

export default WelcomeSlidesContext;
