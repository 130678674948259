import { GET_USER_LOGIN_DETAILS } from 'src/graphql/queries';
import { sendMessageToSlack } from 'src/utils/slackService';
import { throwError } from 'src/utils/errorHandling';

const supportResolvers = {
  Query: {},
  Mutation: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    sendSupportMessage: async (_: any, { message }: any, { client }: any) => {
      const { user, company } = client.readQuery({
        query: GET_USER_LOGIN_DETAILS,
      });

      const slackMessage = `*${user.fullName}* from company *${company.name}* just sent a support message. Be quick and reply to *${user.email}*. Here is the message content: \n\n${message}`;
      try {
        await sendMessageToSlack(slackMessage);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        throwError(error);
      }
    },
  },
};

export default supportResolvers;
