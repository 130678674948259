import { gql } from '@apollo/client';
import { USER_FRAGMENT } from 'src/graphql/fragments';

export const REGISTER_VIA_PASSWORD = gql`
  mutation RegisterUser($email: String!, $password: String!, $termsAccepted: Boolean!) {
    registerViaPassword(email: $email, password: $password, termsAccepted: $termsAccepted) @client
  }
`;

export const REGISTER_VIA_MICROSOFT = gql`
  mutation RegisterViaMicrosoft {
    registerViaMicrosoft @client
  }
`;

export const REGISTER_INVITED_USER = gql`
  mutation RegisterInvitedUser($input: InvitedUserRegistrationInput!) {
    registerInvitedUser(input: $input) {
      ...userValues
    }
  }
  ${USER_FRAGMENT}
`;

export const REGISTER_USER = gql`
  mutation RegisterUser($email: String!) {
    registerUser(email: $email)
  }
`;

/*
input InviteUserInput {
    email: String!
    role: String!
    position: String
}
*/

export const INVITE_USERS = gql`
  mutation InviteUsers($users: [InviteUserInput]!) {
    inviteUsers(users: $users) {
      edges {
        cursor
        node {
          ...userValues
        }
      }
    }
  }
  ${USER_FRAGMENT}
`;

export const REINVITE_USER = gql`
  mutation ReinviteUser($id: ID!) {
    reinviteUser(id: $id) {
      ...userValues
    }
  }
  ${USER_FRAGMENT}
`;

export const EDIT_USER = gql`
  mutation EditUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        ...userValues
      }
    }
  }
  ${USER_FRAGMENT}
`;

export const DELETE_USER = gql`
  mutation DeleteUser($id: ID!) {
    deleteUser(id: $id) {
      id
      message
    }
  }
`;

export const CHANGE_USER_ROLE = gql`
  mutation ChangeUserRole($id: ID!, $role: UserRole!) {
    changeUserRole(id: $id, role: $role) {
      ...userValues
    }
  }
  ${USER_FRAGMENT}
`;

export const UPDATE_ONBOARDING = gql`
  mutation OnboardingComplete {
    completeWelcomeSlides {
      user {
        ...userValues
      }
    }
  }
  ${USER_FRAGMENT}
`;

export const STORE_USER_EMAIL = gql`
  mutation StoreUserEmail($email: String!) {
    storeUserEmail(email: $email) @client
  }
`;
