import React from 'react';
import { IconButton, Menu, MenuItem, MenuProps } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import LanguageIcon from '@mui/icons-material/Language';
import { useTranslation } from 'react-i18next';

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    width: '54px',
    boxShadow: theme.custom.boxShadow,
    border: theme.custom.border,
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
  },
}));

const LanguageSwitch = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { i18n } = useTranslation();
  const theme = useTheme();

  const handleLanguageSwitch = (lang: string) => {
    i18n.changeLanguage(lang).then(() => {
      setAnchorEl(null);
    });
  };

  return (
    <>
      <IconButton
        size="medium"
        aria-label="language"
        disableTouchRipple
        sx={{
          mr: '8px',
        }}
        onClick={handleClick}
      >
        <LanguageIcon />
      </IconButton>
      <StyledMenu theme={theme} anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={() => handleLanguageSwitch('en')} disableRipple>
          EN
        </MenuItem>
        <MenuItem onClick={() => handleLanguageSwitch('de')} disableRipple>
          DE
        </MenuItem>
      </StyledMenu>
    </>
  );
};

export default LanguageSwitch;
