import React from 'react';
import Carousel from 'react-multi-carousel';
import carouselContent from 'src/constants/signupCarouselContent';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

interface DotProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: any;
  primary?: boolean;
  active?: boolean;
}

interface ArrowProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rest?: any;
}

const Dot = ({ onClick, active }: DotProps) => {
  return (
    <Box
      component="div"
      sx={{
        position: 'relative',
        height: active ? '10px' : '8px',
        width: active ? '10px' : '8px',
        backgroundColor: active ? 'text.primary' : 'text.secondary',
        margin: active ? '4px 5px' : '5px',
        outline: 'none',
        boxShadow: 'none',
        border: 'none',
        cursor: 'pointer',
        borderRadius: '10px',
        transition: 'width 0.125s ease-out',
      }}
      onClick={onClick}
    />
  );
};

const arrowStyles = {
  position: 'absolute',
  width: '32px',
  height: '32px',
  borderRadius: '32px',
  border: 'none',
  top: '50%',
  backgroundColor: 'white',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
  opacity: 1,
  transition: 'all 0.125s ease-out',
  '&:hover': {
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
  },
  color: 'background.paper',
  fontSize: '1.25rem',
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CustomRightArrow = ({ onClick, ...rest }: ArrowProps) => {
  return (
    <Box
      component="div"
      onClick={() => onClick()}
      sx={{
        right: '32px',
        ...arrowStyles,
      }}
    >
      <ArrowForwardIcon fontSize="inherit" />
    </Box>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CustomLeftArrow = ({ onClick, ...rest }: ArrowProps) => {
  return (
    <Box
      component="div"
      onClick={() => onClick()}
      sx={{
        left: '32px',
        ...arrowStyles,
      }}
    >
      <ArrowBackIcon fontSize="inherit" />
    </Box>
  );
};

const SignupCarousel = () => {
  const { t } = useTranslation();
  const responsive = {
    oneSize: {
      breakpoint: { max: 4000, min: 0 },
      items: 1,
    },
  };
  return (
    <Box
      sx={{
        position: 'absolute',
        width: '100%',
        height: '100%',
      }}
    >
      <Carousel
        responsive={responsive}
        transitionDuration={200}
        customDot={<Dot />}
        showDots
        renderButtonGroupOutside
        draggable={false}
        swipeable={false}
        customLeftArrow={<CustomLeftArrow />}
        customRightArrow={<CustomRightArrow />}
      >
        {carouselContent.map((slideContent, index) => {
          return (
            <Box
              pt={12}
              key={index}
              sx={{
                position: 'relative',
                height: '90vh',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
              }}
            >
              <Box pb={2}>
                <img
                  src={slideContent.imageSrc}
                  alt={t(slideContent.title)}
                  style={{
                    width: 400,
                    height: 340,
                  }}
                />
              </Box>
              <Box py={2}>
                <Typography variant="h4">{t(slideContent.title)}</Typography>
              </Box>
              <Box pt={1}>
                <Typography variant="subtitle1">{t(slideContent.subtitle)}</Typography>
              </Box>
            </Box>
          );
        })}
      </Carousel>
    </Box>
  );
};

export default SignupCarousel;
